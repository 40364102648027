export default function FormService ($q) {
	var service = {};
	
	service.data = {
		forms: null,
		formIndex: 0,
		form: null,
		pageIndex: 0,
		page: null,
		isLastPageOfStep: false
	};

	//finds the indexes of any items in a list that have property isCurrent = true. Can be used for lists of forms or pages
	function getCurrentIndex(list) {
		var indexesMarkedCurrent = [];

		for (var i = 0; i < list.length; i++) {
			if (list[i].isCurrent) {
				indexesMarkedCurrent.push(i);
			}
		}

		if (indexesMarkedCurrent.length > 1) {
			throw 'There are multiple items marked as current. Only one item at a time should be current.';
		}

		//either return the index marked as current, or return zero and we will default to the first item being current
		return indexesMarkedCurrent.length === 1 ? indexesMarkedCurrent[0] : 0;
	}

	//Gets relevant metadata about the isCurrent item in a list. Can be used for lists of forms or pages
	function getCurrent (list) {
		var currentIndex = getCurrentIndex(list);
		return {
			index: currentIndex,
			item: list[currentIndex],
			isLastItem: currentIndex === (list.length - 1)
		};
	}

	//sets isCurrent=false for on all forms and all pages
	function clearIsCurrent(forms) {
		forms.map(function (item) {
			item.isCurrent = false;
			
			item.subItemList.map(function(subitem) {
				subitem.isCurrent = false;
			});
		});
	}

	//performs all the actions required to setup a new form as "current"
	function setupNewForm(newForms, newFormIndex, newPageIndex) {
		//if pageIndex is not passed, assume first page
		newPageIndex = newPageIndex || 0;

		var newForm = newForms[newFormIndex];
		var newPage = newForm.subItemList[newPageIndex];

		// clear all currently marked as isCurrent 
		clearIsCurrent(newForms);

		newForm.isCurrent = true;
		newPage.isCurrent = true;

		var newCurrentFormData = getCurrent(newForms);
		var newCurrentPageData = getCurrent(newForm.subItemList);
		
		service.data.formIndex = newFormIndex;
		service.data.form = newForm;
		service.data.pageIndex = newPageIndex;
		service.data.page = newPage;
		service.data.isLastPageOfStep = newCurrentFormData.isLastItem && newCurrentPageData.isLastItem;
	}

	//sets up the service data using the data object brought back from our api 
	service.pageData = function (data) {
		//perform calculations needed to setup the service data
		var forms = data.processItemsList;
		var currentFormData = getCurrent(forms);
		var currentPageData = getCurrent(currentFormData.item.subItemList);

		//set service data
		setupNewForm(forms, currentFormData.index, currentPageData.index);
		
		return $q.when(service.data);
	};

	service.changeForm = function (e, newFormIndex, content) {
		/* leaving this in case there was a reason for it so we can add it back in later */
		//if (formIndex === service.formIndex) defer.reject('edge case failure?');
		
		//setup data needed to change form
		var forms = content.processItemsList;

		//setup the new service data
		setupNewForm(forms, newFormIndex);

		return $q.when(service.data);
	};

	service.goToPage = function(data, index, forms) {
		var currentFormIndex = data.formIndex;
		var onFirstForm = currentFormIndex === 0;
		var onLastForm = currentFormIndex === (forms.length - 1);
		var targetPageIndex = index;
		var moveToNextForm = targetPageIndex > (data.form.subItemList.length - 1);
		var moveToPreviousForm = targetPageIndex < 0;

		//assume we are staying on same form by default
		var targetFormIndex = currentFormIndex;

		//update the target form and page indexes
		if(!onFirstForm && moveToPreviousForm) {
			targetFormIndex--;
			// if moving back to a previous form, the previous form must have been 
			// complete (so only a single review step remains) or it
			// was recertified, so we'd start at the first page. 
			targetPageIndex = 0; 
		}
		else if(onFirstForm && moveToPreviousForm) {
			targetPageIndex = 0;
		}
		else if(!onLastForm && moveToNextForm) {
			targetFormIndex++;
			targetPageIndex = 0;
		}
		else if (onLastForm && moveToNextForm) {
			// if attempting to move to the next form when on the last form
			// then just reset the targetPageIndex to the current pageIndex
			// and remain on the same form. isLastPageOfStep will take care of
			// exiting the step
			targetPageIndex = data.pageIndex;
		}
		
		//set service data 
		setupNewForm(forms, targetFormIndex, targetPageIndex);

		return $q.when(service.data);
	}
	
	return service;
};

FormService.$inject = ['$q'];