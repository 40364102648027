<template>
    <div v-if="isLoggedOut">
        <logged-out></logged-out>
    </div>
</template>

<script>
import analyticsService from 'common/services/analytics.service';
import { USER_SIGNED_OUT } from 'analytics/event-types';
import appSettings from "appSettings";
import { authService } from 'modules/common.module.js'
import loggedOut from '../login/logged-out.vue'

export default {
    name: "logout-view",
    data() {
        return {
            isLoggedOut: false
        }
        
    },
    components: {
        loggedOut
    },
    mounted() {
        let hasAuthHeader = authService.getAuthHeader();

        if (hasAuthHeader) {
          authService.clearSession();
        }

        //if we had an auth header, or the logged out confirmation wasn't provided.
        if (hasAuthHeader || !this.$_cb.route.query.loggedOut) {
          analyticsService.track(USER_SIGNED_OUT, {});
          analyticsService.reset();
          //return here when done, with the extra query parameter indicating the user has been logged out.
          //the route is encoded and added to the base url, as auth0 is removing the hash fragment.
          const postLogoutRedirectUri = window.location.origin + appSettings.baseUrl + '?hash=' + encodeURIComponent(appSettings.viewLogout + '?loggedOut=true');
          let url = appSettings.apiBaseUrl + "Login/Logout?post_logout_redirect_uri=" + encodeURIComponent(postLogoutRedirectUri);

          this.$http.get(url).then(function (response) {
            window.location.href = response.data.redirectUrl;
          });
        }
        else {
          this.isLoggedOut = true;
        }
    }
    
}
</script>