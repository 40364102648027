<template>
  <div class="cb-message  vert-c"
       :class="template"
       v-if="visible">
    <svg class="cb-icon-message-icon margin-sm-R">
      <use class="cb-icon-message-error-icon"
           v-show="template === 'error'"
           :href="getIconPath('Message-Error-Warning-Icon')"></use>
      <use class="cb-icon-message-info-icon"
           v-show="template === 'info'"
           :href="getIconPath('Message-Info-Icon')"></use>
      <use class="cb-icon-message-warning-icon"
           v-show="template === 'warning'"
           :href="getIconPath('Message-Error-Warning-Icon')"></use>
      <use class="cb-icon-message-success-icon"
           v-show="template === 'success'"
           :href="getIconPath('Message-Success-Icon')"></use>
      <use class="cb-icon-message-pre-success-icon"
           v-show="template === 'pre-success'"
           :href="getIconPath('Message-Success-Icon')"></use>
    </svg>
    {{ message }}
  </div>
</template>

<script>
import {IconProvider} from 'common/icon.module';
import {mapGetters} from 'vuex'

export default {
  name: "ui-messages",
  computed: {
    ...mapGetters('ui-messages', ['message', 'template', 'visible'])
  },
  methods: {
    getIconPath(iconName) {
      return IconProvider.getPath(iconName);
    }
  }
}
</script>

<style scoped>

</style>