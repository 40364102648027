/*
 * intercepts XHR requests and manages Authorization headers
 */

var ErrorInterceptorModule = angular.module("errorInterceptorModule", [])
	.config(["$httpProvider", function ($httpProvider) {
		var interceptor = ['$q', 'CommonErrorService', function ($q, CommonErrorService) {
			var checkResponse = function (response) {
				// catch all error status codes
				if (response.status >= 400) {
					CommonErrorService.saveError(response.data);
				}
			};
			return {
				responseError: function (rejection) {
					checkResponse(rejection);
					return $q.reject(rejection);
				}
			}
		}];

		$httpProvider.interceptors.push(interceptor);
	}]);

export default ErrorInterceptorModule.name;