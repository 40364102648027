<template>
  <section class="signed-document-pdf-viewer-wrapper">
    <cb-pdf-viewer v-if="pdfUrl" :url="pdfUrl" :labels="pdfViewerLabels" />
  </section>
</template>

<script>
import FormNavigationService from 'common/forms/form-navigation.service';
import PrimaryMessageBus from 'common/components/cb-primary-message-bus';
import CbPdfViewerDataProvider from 'common/components/pdf/cb-pdf-viewer-data-provider';

export default {
  name: 'signed-document',
  components: {
    CbPdfViewer: () => import('@clickboarding/pdf-viewer')
  },
  props: {
    contentUrl: {
      required: false,
      type: String
    },
    eventUrl: {
      required: false,
      type: String
    },
    onNextSuccess: {
      required: false,
      type: Function
    },
    onLoad: {
      required: true,
      type: Function
    }
  },
  created () {
    CbPdfViewerDataProvider.getData().then(data => {
      this.pdfViewerLabels = data;
    });
  },
  mounted () {
    FormNavigationService.setNextClickHandler(this.save);

    this.$http.get(this.contentUrl).then((response) => {
      this.pdfUrl = response.body.url;
      this.onLoad();
    });
  },
  destroyed () {
    FormNavigationService.deleteNextClickHandler();
  },
  methods: {
    save () {
      this.$http.post(this.eventUrl).then((response) => {
        this.onNextSuccess(response.body);
        PrimaryMessageBus.$emit('clear-all-messages');
      });
    }
  },
  data () {
    return {
      pdfUrl: null,
      pdfViewerLabels: null
    };
  }
}
</script>
<style lang="scss">
.signed-document-pdf-viewer-wrapper {
  // position, overflow, and height styles were 
  // copied from components.scss for the time
  // being. We have to leave them there for 
  // rose PDF forms. Until then, we should keep 
  // these in sync
	position: relative;
  overflow: hidden;
  height: 43.75rem;
}
</style>
