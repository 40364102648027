import appInsightsAnalytics from 'analytics/app-insights-analytics';
import { authServiceEventBus, AuthServiceEvents } from 'common/auth.service';
import store from "store";

export default angular.module('app-insights-analytics-module', [])
  .factory('analytics', function appInsightsAnalyticsFactory() {
    var analytics = appInsightsAnalytics;
    analytics.setApp('Candidate');
    // Try to immediately set the authenticatedUser, assuming
    // it exists due to a redirect that got us to this app.
    var userId = store.getters['auth/getAuthenticatedUserId'];
    if (userId) {
      analytics.setAuthenticatedUserId(userId);
    }
    return analytics;
  })
  .service('analyticsUrlSanitizerSvc', function () {
    return {
      sanitizeUrl: function (url) {
        // For now just omit the query string from the analytics in the url
        // In Angular, this *could* be part of of the hash fragment,
        // so we'll just naively strip the string after the first ?
        const queryStart = url.indexOf("?");
        return (queryStart > -1) ? url.substring(0, queryStart) : url;
      }
    }
  })
  .config(["$provide", function($provide) {
    // Register an exception interceptor for analytics
    $provide.decorator('$exceptionHandler', ['$delegate', function ($delegate) {
      return function (exception, cause) {
        // Best effort logging here only to try to prevent an infinite loop
        try {
          appInsightsAnalytics.logException(exception, { cause: cause });
        } catch(e) {}

        // Call the original exception handler as well
        $delegate(exception, cause);
      }
    }])
  }])
  // Setup events for the app on module load
  .run(["$rootScope", "analytics", "$location", "analyticsUrlSanitizerSvc", function ($rootScope, analytics, $location, analyticsUrlSanitizerSvc) {
    let locationChangeStartTimeMs = null;
    $rootScope.$on("$locationChangeStart", () => {
      locationChangeStartTimeMs = (new Date()).getTime();
    });
    $rootScope.$on("$locationChangeSuccess", (e, newUrl) => {
      var name = $location.path();

      var durationMs = 0; // Best-effort duration calculation (0ms will get logged if our calc fails for any reason, like out-of-order events)
      if (locationChangeStartTimeMs) {
        durationMs = (new Date()).getTime() - locationChangeStartTimeMs;
        locationChangeStartTimeMs = null;
      }

      analytics.logPageView(name, analyticsUrlSanitizerSvc.sanitizeUrl(newUrl), null, null, durationMs);
    });

    authServiceEventBus.on(AuthServiceEvents.AuthenticatedUserChanged, (event) => { 
      if (event && event.user && event.user.id) analytics.setAuthenticatedUserId(event.user.id);
      else analytics.clearAuthenticatedUser();
    })
    authServiceEventBus.on(AuthServiceEvents.SessionCleared, () => {
      analytics.clearAuthenticatedUser();
    });
  }])
  .name;
