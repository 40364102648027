<template>  
  <section v-show="model.pageRendered" style="overflow:auto;">
      <div :class="subTypeEnum === 'Symmetry' || subTypeEnum == 'Retain' ? 'symmetry' : 'formI9'">
        <iframe :src="externalUrl"
                :scrolling="subTypeEnum === 'Symmetry' || subTypeEnum == 'Retain'? 'yes' : 'auto'"
                frameborder="0"></iframe>
        <div class="button-bar d-flex">
          <!--extra space hack-->
          <button class="cb-btn primary next flex-fill" v-if="subTypeEnum !== 'Symmetry' && subTypeEnum !== 'Retain'" @click="handleClose">Continue</button>
        </div>
    </div>
  </section>
</template>

<script>

export default {
  name: "external",
  components: {
  },
  props: {
    contentUrl: String,
    subTypeEnum: String,
    eventUrl: String,
    model: Object
  },
  data() {
    return {        
        content: null,
        externalUrl: null
      };
  },
  computed: {    
  },
  methods: {
    async loadData() {
      const response = await this.$http.get(this.contentUrl);
      this.model.pageRendered = true;
      this.content = response.data;
          this.externalUrl = this.content.contentUrl
          console.log("external-form.vue loadData start");
    },
    
    async handleClose () {
        console.log("external-form.vue handleClose start");
        const data = await this.$http.post(this.eventUrl);
        console.log("external-form.vue handleClose nextSuccess");
      this.model.nextSuccess(data.data);
    },

    externalOnMessage(e) {
        console.log("external-form.vue externalOnMessage start");
        if (e) {
        const data =  e.data;
        if (typeof data === 'string' && JSON.parse(data)['event'] === 'onDone') {
            console.log("external-form.vue externalOnMessage handleClose");
            this.handleClose();
        }
      }
    }
  },
  async mounted() {
    await this.loadData();
  },
        created: function () {
            console.log("external-form.vue created addEventListener message");
    window.addEventListener('message',this.externalOnMessage);
  },
  destroyed: function() {
    window.removeEventListener('message', this.externalOnMessage);
  }
};
</script>