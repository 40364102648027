import CommonValues from 'common/settings/values';

export default function CommonModalService(CommonEventService) {
	function toggleBodyClass(open) {
		var elements = document.getElementsByTagName('body');
		if (elements.length) {
			var element = elements[0];
			if (open === true) {
				element.classList.add('modal-open');
			} else {
				element.classList.remove('modal-open');
			}
		}
	}

	var svc = {};
	var openModalEvent = "OpenModal";
	var closeModalEvent = "CloseModal";

	CommonEventService.register(openModalEvent);
	CommonEventService.register(closeModalEvent);

	function getModalTemplate(str){
		var modalTemplate;

		switch(str) {
			case 'confirm':
				modalTemplate = CommonValues.modalTemplates.confirm;
				break;
			case 'pdf':
				modalTemplate = CommonValues.modalTemplates.pdf;
				break;
			case 'legal':
				modalTemplate = CommonValues.modalTemplates.legal;
				break;
		}

		return modalTemplate;
	}

	svc.previousElement = null;

	svc.open = function(data, tmpl, action) {
		var modalTemplate = getModalTemplate(tmpl);
		svc.previousElement = document.activeElement;

		toggleBodyClass(true);

		// add modal type to data object
		data.type = tmpl;

		CommonEventService.publish(openModalEvent, {
			template: modalTemplate,
			show: true,
			action: action,
			data: data
		});
	};

	svc.close = function() {
		toggleBodyClass(false);
		if (svc.previousElement) svc.previousElement.focus();
		svc.previousElement = null;
		CommonEventService.publish(closeModalEvent, {
			show: false
		});
	};

	svc.onOpen = function (action, scope) {
		return CommonEventService.subscribe(openModalEvent, action, scope);
	};
	svc.onClose = function (action, scope) {
		return CommonEventService.subscribe(closeModalEvent, action, scope);
	};

	return svc;
};

CommonModalService.$inject = ['CommonEventService'];
