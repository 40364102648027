let rootUrl = document.getElementById("linkRoot").getAttribute("href");

export default {
		appType: "candidate",
		viewPath: '',
		baseUrl: rootUrl,
		headerEndpoint: "cbcandidatehdrUrl",
		//cbSettings is a global variable set on the cshtml files and is dynamic based on asp.net configuration values
		apiBaseUrl: cbSettings.apiBaseUrl,
		cdnBaseUrl: cbSettings.cdnBaseUrl,

		loginUrl: cbSettings.loginUrl,
		iconSpritePath: 'assets/icons/icon-sprite.svg',

		//login app
		viewLogin: '/login',
		viewLoginCallback: '/login/callback',
		viewAutoLogin: '/autologin',
    	viewSignedIn:'/signedIn',
		viewLogout:'/logout',
		
		//native login
		viewInitial: '/initial',
		viewProfile: '/profile',
		viewNativeLoginDefault: '/login/native/default',
		viewNativeLoginInitial: '/login/native/initial',
		viewNativeLogout: '/login/native/logout',
		viewReset: '/reset',
		viewSecurity: '/security',

		viewHome:'/process',
		viewActivitiesOverview: '/overview',
		viewFormSplash: '/forms-splash',
		viewProcess: '/process',
		viewForms: '/forms',
		viewHtml: '/html',
		viewError: '/error',
		viewUnsupported: '/unsupported',
		appEventPrefix: 'cb:candidate:',

		customLoginConfig: '/login/customloginconfig',
		
		// web perfomance monitoring
		env: cbSettings.env,

		appInsights: {
			enabled: cbSettings.appInsights && cbSettings.appInsights.enabled,
			instrumentationKey: cbSettings.appInsights && cbSettings.appInsights.instrumentationKey,
		},

		candidateFeatureFlagFeatureToggle: cbSettings.candidateFeatureFlagFeatureToggle
	}
