export default function securityCtrl ($rootScope, $http, $q, uiMessages, appSettings, locationSvc, AuthService, ContentService) {
	AuthService.clearSession();

	var vm = this;

	vm.title = ContentService.getString('headerTitle.message');
	
	$http.get(locationSvc.fetchQuery()).then(function (data) {
		vm.content = data.data;
		uiMessages.showMessage('info', data.data.instructions);
	});

	vm.handleSecurity = function (e) {
		var payload = { "id": vm.content.question.id, "answer": vm.model.answer1 };
		return $http.post(vm.content.submit.url, payload).then(function (data) {
			if (data.data.statusEnum == "Success") {
				locationSvc.changeView(appSettings.viewReset, data.data.url);
				uiMessages.hideMessage();
			}
			else if (data.data.statusEnum == "Fail") {
				uiMessages.hideMessage();
				uiMessages.showMessage('error', data.data.message);
			}
		});
	};
};

securityCtrl.$inject = ['$rootScope', '$http', '$q', 'uiMessages', 'appSettings', 'locationSvc', 'AuthService', 'ContentService'];

