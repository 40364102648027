async function firstTimeLoginCtrl (appSettings, $http, locationSvc) {
    let dataUrl = locationSvc.fetchQuery();

    const response = await $http.get(dataUrl);

    const param = {};

    param.idp = response.data.identityProvider;

    if(response.data.queryStringAuth) {
        param.qs = response.data.queryStringAuth;
    }

    locationSvc.goToView({
        route: appSettings.viewLogin,
        params: param
    });
};

export default firstTimeLoginCtrl;

firstTimeLoginCtrl.$inject = ['appSettings', '$http', 'locationSvc'];
