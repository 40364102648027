async function nativeLoginInitialCtrl (appSettings, $location, $http, locationSvc) {
	let url = appSettings.apiBaseUrl + "login/native/initial";
  
  let params = $location.search();
  let host = window.location.origin;

  const response = await $http.post(url, {
		redirectUri: params.redirect_uri,
    state: params.state,
    host: host
  });

  if (response.data.statusEnum === 'Success') {
    if(response.data.redirectUri) {
      window.location.replace(response.data.redirectUri);
    }
    else {
      locationSvc.changeView(appSettings.viewLogin);
    }    
  }
  else {
    locationSvc.goToView({
      route: appSettings.viewNativeLoginDefault,
      params: params
    });
  }
};

export default nativeLoginInitialCtrl;

nativeLoginInitialCtrl.$inject = ['appSettings', '$location', '$http', 'locationSvc'];
