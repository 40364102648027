//this file should eventually go away in favor of feature specific modules defined in the SharedUI project common module js
import DeviceDetectorModule from 'common/device-detector.module';
import AppSettingsModule from 'common/app-settings.module';

import AuthorizationInterceptorModule from 'interceptors/authorization.interceptor';

import AppRoutingModule from 'common/app-routing.module';
import CommonService from 'common/services/generic.service';
import CommonEventService from 'common/services/event.service';
import CommonModalService from 'common/services/modal.service';
import AuthService from 'common/auth.service';
import CommonFormValidation from 'common/services/formValidation.service';
import CbModalDirective from 'common/components/modal/modal.directive';
import CbFormSplash from 'general/cb-form-splash.vue';
import FiltersModule from 'common/filters.module';
import ContentService from 'content.service';
import CandidateExperienceService from 'candidateExperience.service'
import CandidateProcessService from 'services/candidateProcess.service'

export let candidateProcessService;
export let experienceService;
export let contentService;
export let processService;
export let authService;

export let commonModalService;
export default angular.module('candidate-common-module', [ AppSettingsModule, AuthorizationInterceptorModule, DeviceDetectorModule, FiltersModule, AppRoutingModule ])
  .factory('CommonService', CommonService)
  .factory('CommonEventService', CommonEventService)
  .factory('CommonModalService', CommonModalService)
  .factory('AuthService', AuthService)
  .factory('CommonFormValidation', CommonFormValidation)
  .factory('ContentService', ContentService)
  .factory('ExperienceService', CandidateExperienceService)
  .factory('CandidateProcessService', CandidateProcessService)
  .directive('cbModal', CbModalDirective)
  .value('cb-form-splash', CbFormSplash)
  .run(['$injector', function($injector) {
    commonModalService = $injector.get('CommonModalService');
    candidateProcessService = $injector.get('CandidateProcessService');
    experienceService = $injector.get('ExperienceService');
    contentService = $injector.get('ContentService');
    processService = $injector.get('ProcessService');
    authService = $injector.get('AuthService');
  }])
  .name;
