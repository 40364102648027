import PrimaryMessageBus from 'common/components/cb-primary-message-bus';

export default function cbAppController ($scope, $rootScope, $http, $window, appSettings, locationSvc, CommonErrorService, uiMessages) {
  $scope.$on('$routeChangeStart', function (next, current, previous) {
    locationSvc.storeCurrentRoute(previous);
  });

  $scope.$on('$viewContentLoaded', function (event) {
    var requiredElements = Array.from(document.querySelectorAll('[required]'));

    requiredElements.forEach(function(element) {
      if(element.tagName.toLowerCase() === 'input'
        && element.attributes["type"].value.toLowerCase() !== 'radio'
        && element.previousElementSibling.tagName.toLowerCase() === 'label') {
        element.previousElementSibling.classList.add("required");
      }

      element.setAttribute('aria-required', true);
    });

    var selectElements = Array.from(document.querySelectorAll('body select'));

    selectElements.forEach(function(element) {
      element.addEventListener('change', function (event) {
        if(event.target.value === "") {
          event.target.classList.add('selectPlaceholder');
        }
        else {
          event.target.classList.remove('selectPlaceholder');
        }
      });
    });
  });

  var deregisterLoadingBarError = $rootScope.$on('cfpLoadingBar:error', showErrorDirective);
  function showErrorDirective(e, data) {
    var isSoftError = data && data.length == 1 && data[0].message && data[0].typeEnum === 'Soft';
    if (!isSoftError) {
      CommonErrorService.saveError(data);
      locationSvc.changeView(appSettings.viewError, data);
    }
  }

  var deregisterManualProgressShow = $rootScope.$on('cbAppCtrl:manualProgressShow', function () { $scope.showManualProgress = true; });
  var deregisterManualProgressHide = $rootScope.$on('cbAppCtrl:manualProgressHide', function () { $scope.showManualProgress = false; });

  $scope.handleViewHtml = function () {
    locationSvc.changeView(appSettings.viewHtml);
  };

  /* Shim to forward primary-message-bus messages to the uiMessages service (subscribing) */
  PrimaryMessageBus.$on('send-info-message', (message) => { uiMessages.showMessage('info', message); });
  PrimaryMessageBus.$on('send-warning-message', (message) => { uiMessages.showMessage('warn', message); });
  PrimaryMessageBus.$on('send-error-message', (message) => { uiMessages.showMessage('error', message); });
  PrimaryMessageBus.$on('send-success-message', (message) => { uiMessages.showMessage('success', message); });
  PrimaryMessageBus.$on('clear-all-messages', uiMessages.hideMessage);

  $scope.$on('$destroy', function() {
    deregisterLoadingBarError();
    deregisterManualProgressShow();
    deregisterManualProgressHide();

    /* Shim to forward primary-message-bus messages to the uiMessages service (unsubscribing) */
    PrimaryMessageBus.$off('send-info-message');
    PrimaryMessageBus.$off('send-warning-message');
    PrimaryMessageBus.$off('send-error-message');
    PrimaryMessageBus.$off('send-success-message');
    PrimaryMessageBus.$off('clear-all-messages');
  });
};

cbAppController.$inject = ['$scope', '$rootScope', '$http', '$window', 'appSettings', 'locationSvc', 'CommonErrorService', 'uiMessages'];
