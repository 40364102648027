<template>
  <section v-if="content" >
    <div v-if="content" class="viewSubContent e-sig-agreement">
      <div v-html="content.consent"></div>
    </div>
    <div v-if="agreeRadioData && agreeModel">
      <cb-radio-group 
        :value="agreeModel.agree"
        :agree="agreeRadioData.agree"
        :disagree="agreeRadioData.disagree"
        :message-type="'warning'"
        :message="agreeRadioData.disagree.warning"
        @input="onChangeAgree"
        >
      </cb-radio-group>
    </div>
    <div class="h-line" v-show="agreeModel.agree === content.agree.value"></div>
    <div v-show="agreeModel.agree === content.agree.value" class="signature-pad" >
      <ui-messages class="margin-med-B"></ui-messages>
      <label class="required">{{content.signatureLabel}}</label>
      <div class="signature" required>
        <div class="instructions padding-sm">
          {{content.instructions}}
          <br />
          <a class="link" @click="signaturePad.clear()">{{content.clearLabel}}</a>
        </div>
        <canvas id="cSignature" aria-label="e-signature area" tabindex="0"></canvas>
      </div>
      <div class="input-contain">
        <label for="name" class="required">{{content.candidateName.label}}</label>
        <input id="name"
            type="text"
            autofocus
            tabindex="0"
            name="signatureName"
            v-model="content.candidateName.value"
            :placeholder="content.namePlaceholder"
            required />
      </div>
    </div>
  </section>
</template>

<script>
import UiMessages from "../components/ui-messages/ui-messages.vue";
import CbRadioGroup from "../general/cb-radio-group.vue";
import FormNavigationService from 'common/forms/form-navigation.service';

import {mapActions} from 'vuex';

export default {
  name: 'signature-create',
  components: {
    CbRadioGroup,
    UiMessages
  },
  props: {
    currentPage: {
      required: false,
      type: Object  
    },
    form: {
      required: false,
      type: Object
    },
    model: {
      required: false,
      type: Object
    },
    onNextSuccess: {
      required: false,
      type: Function
    }
  },
  async mounted () {
    if(typeof this.currentPage !== "undefined") {
      await this.loadData(); 
    }    
  },
  destroyed () {
    FormNavigationService.deleteNextClickHandler();
    if (this.observer) { 
			this.observer.disconnect();
			this.observer = null;
		}
  },
  watch: {
    currentPage(newVal, oldVal) {
      if (newVal === oldVal) 
        return;

      else {
        this.loadData();
      }
    },
    agreeModel(newVal, oldVal) {
      this.observer = new MutationObserver(function(mutations) {
          const canvasElement = document.getElementById('cSignature');
          if (!this.hasSizedCanvas && canvasElement.parentNode.clientWidth) {
            canvasElement.style.width = canvasElement.parentNode.clientWidth + 'px';
            canvasElement.width = canvasElement.parentNode.clientWidth;
            this.observer.takeRecords(); 
            this.hasSizedCanvas = true; 
          } 
        });
        this.observer.observe(this.containerElement, {
          subtree: false,
          childList: false,
          attributes: true,
          characterData: false
        });
    }        
  },
  methods: {
    ...mapActions('ui-messages', ['showMessage', 'hideMessage']),
  
    onChangeAgree (value) {
      FormNavigationService.setNextClickHandler(this.submitSignature);
      this.agreeModel.agree = value ?  value : null;
      
      if(value === this.agreeRadioData.agree.value) {
        this.hasSizedCanvas = false;
        this.containerElement = document.getElementsByClassName('signature-pad')[0];
        this.signaturePad = new SignaturePad(document.getElementById('cSignature'));
        if ( this.content.imageString !== "")
          this.signaturePad.fromDataURL(this.content.imageString);
      }
      
    },

    submitSignature () {

      if (this.agreeModel.agree === this.content.agree.value && this.content.candidateName.value && !this.signaturePad.isEmpty()) {
				this.$http.post(this.currentPage.eventUrl, { signedName: this.content.candidateName.value, imageString: this.signaturePad.toDataURL(), agreeDisagree: this.agreeModel.agree })
					.then(function (response) {
						this.onNextSuccess(response.data);
					});
			} else if (this.agreeModel.agree === this.content.agree.value) { // Only show missing signature error if user agrees
					if (this.signaturePad.isEmpty()) this.showMessage('error', this.content.missingSignatureWarning);
			} else if (this.agreeModel.agree === this.content.disagree.value) {
				this.$http.post(this.currentPage.eventUrl, { agreeDisagree: this.agreeModel.agree })
					.then(function (response) {
						this.onNextSuccess(response.data);
					});
			}
    },

    async loadData() {
      const response = await this.$http.get(this.currentPage.contentUrl);
      this.model.pageRendered = true;
      this.content = response.data;
      this.agreeRadioData = this.content;      
      this.signedNameValidation = {
        type: [{name:'required', message: this.content.validationMessages.required}],
        name: response.data.candidateName.label.$error
      };     
    },
  },
  data () {
    return {
      observer: null,
      content: null,
      agreeModel: {
        agree: null,
        disagree: null
      },
      agreeRadioData: null,
      signedNameValidation: null,
      signaturePad: null,
      hasSizedCanvas: null
    };
  }
}
</script>
<style lang="scss">
</style>
