export default function profileCtrl ($http, $timeout, $document, $location, $scope, uiMessages, appSettings, locationSvc, CommonService, CommonModalService, ContentService) {
  var vm = this;
  vm.model = {
    securityQuestionAnswers: [
      { id: '', answer: ''},
      { id: '', answer: ''}
    ]
  };
  vm.password1 = {};
  vm.password2 = {};
  vm.agreeTerms = false;
  vm.agreePrivacy = false;
  vm.termsChecked = false;
  vm.privacyChecked = false;
  vm.policyAgreement = { agree: null };
  vm.termsConditionsDeclined = false;
  vm.submitting = false;
  vm.hasPrivacyPolicyOrTermsAndConditionsChanged = false;
  vm.mfaCode = null;
  vm.mfaRequired = false;
  vm.phoneNumberOptIn = false;

  function renderView(data){
    uiMessages.hideMessage();
    vm.content = data.data;
    vm.hasPrivacyPolicyOrTermsAndConditionsChanged = vm.content.hasPrivacyPoliciesChanged || vm.content.hasTermsAndConditionsChanged;

    vm.agreeTerms = vm.agreeTerms && !vm.content.hasTermsAndConditionsChanged;
    vm.agreePrivacy = vm.agreePrivacy && !vm.content.hasPrivacyPoliciesChanged;

    ContentService.setLocale(data.data.locale);

    vm.model.ISOCountryLanguageGUID = vm.content.language.value;
    vm.model.TimeZoneCodeId = vm.content.timeZone.value.toString();

    vm.languageValidation = {
      type: [{name:'required',message: ContentService.getString('profile.validationMessages.required')}],
      name: ContentService.getString('profile.language.label'),
      error: vm.userForm.language.$error
    };
    vm.timezoneValidation = {
      type: [{name:'required',message: ContentService.getString('profile.validationMessages.required')}],
      name: ContentService.getString('profile.timeZone.label'),
      error: vm.userForm.timezone.$error
    };
    vm.mfaCodeValidation = {
      type: [{name:'required',message: ContentService.getString('profile.validationMessages.required')}],
      name: "MFA Code",
      error: "MFA Code is required"
    };
    vm.mobilePhoneNumberValidation = {
      type: [{name:'required',message: ContentService.getString('profile.validationMessages.required')}],
      name: "Mobile Phone Number",
      error: "Mobile Phone Number is required"
    };

    vm.password1 = {
      label: ContentService.getString('profile.passwordLabel'),
      placeholder: "",
      rules: data.data.passwordRules,
      value: ''
    };
    vm.password2 = {
      label: ContentService.getString('profile.confirmPasswordLabel'),
      placeholder: "",
      doNotMatchMessage: ContentService.getString('profile.passwordRules.doNotMatchMessage'),
      value: ''
    };
    vm.policyAgreementRadioData = {
      agree: {
        label: ContentService.getString('common.accept'),
        value: "2"
      },
      disagree: {
        label: ContentService.getString('common.decline'),
        value: "1"
      },
      disagreeWarning: ContentService.getString('profile.siteTerms.declinedMessage'),
      requiredWarning: ContentService.getString('profile.siteTerms.requiredMessage')
    };

    vm.content.headerTitle =  ContentService.getString('headerTitle.message');
    vm.content.welcome = ContentService.getString('profile.welcome', { givenName: vm.content.givenName });
    vm.content.userNameLabel = ContentService.getString('profile.userName.label');
    vm.content.passwordLabel = ContentService.getString('profile.passwordLabel');
    vm.content.confirmPasswordLabel = ContentService.getString('profile.confirmPasswordLabel');
    vm.content.siteTerms.message = ContentService.getString('profile.siteTerms.message');
    vm.content.siteTerms.termsLabel = ContentService.getString('profile.siteTerms.terms.label');
    vm.content.siteTerms.privacyLabel = ContentService.getString('profile.siteTerms.privacy.label');
    vm.content.siteTerms.acceptLabel = ContentService.getString('common.accept');
    vm.content.siteTerms.requiredMessage = ContentService.getString('profile.siteTerms.requiredMessage');
    vm.content.passwordRules.title = ContentService.getString('profile.passwordRules.title');
    vm.content.passwordRules.doNotMatchMessage = ContentService.getString('profile.passwordRules.doNotMatchMessage');
    vm.content.passwordRules.rules.capital.label = ContentService.getString('profile.passwordRules.rules.capital.label');
    vm.content.passwordRules.rules.lowerCase.label = ContentService.getString('profile.passwordRules.rules.lowerCase.label');
    vm.content.passwordRules.rules.special.label = ContentService.getString('profile.passwordRules.rules.special.label');
    vm.content.passwordRules.rules.number.label = ContentService.getString('profile.passwordRules.rules.number.label');
    vm.content.passwordRules.rules.length.label = ContentService.getString('profile.passwordRules.rules.length.label');
    vm.content.passwordRules.rules.maxLength.label = ContentService.getString('profile.passwordRules.rules.maxLength.label');
    vm.content.submitLabel = ContentService.getString('common.submit');
    vm.content.language.emptyOptionLabel = ContentService.getString('common.select.emptyOption');
    vm.content.language.label = ContentService.getString('profile.language.label');
    vm.content.timeZone.emptyOptionLabel = ContentService.getString('common.select.emptyOption');
    vm.content.timeZone.label = ContentService.getString('profile.timeZone.label');
    vm.content.validationMessages = {
      multipleInvalid: ContentService.getString('profile.validationMessages.multipleInvalid')
    }

    vm.content.hasSomeChanges = ContentService.getString('profile.limited.welcome.someChanges');
    vm.content.siteTerms.reviewUpdatedPolicy = ContentService.getString('profile.siteTerms.reviewUpdatedPolicy');
    vm.content.siteTerms.privacyPolicyChanged = ContentService.getString('profile.siteTerms.privacyPolicyChanged');
    vm.content.siteTerms.termsConditionsChanged = ContentService.getString('profile.siteTerms.termsConditionsChanged');


    if(vm.content.pageMode === 'NativeFirstTimeLogin') {
      vm.policyAgreementRadioData.disagreeWarning = ContentService.getString('profile.siteTerms.declinedMessage');
      vm.content.welcomeBody = ContentService.getString('profile.welcome.value');
      vm.content.declineLabels = {
        title: ContentService.getString('profile.declineLabels.title'),
        message: ContentService.getString('profile.declineLabels.message'),
        returnLabel: ContentService.getString('profile.declineLabels.returnLabel')
      };
    }
    else {
      vm.policyAgreementRadioData.disagreeWarning = ContentService.getString('profile.limited.siteTerms.declinedMessage');
      vm.content.welcomeBody = ContentService.getString('profile.limited.welcome.value');
      vm.content.declineLabels = {
        title: ContentService.getString('profile.limited.declineLabels.title'),
        message: ContentService.getString('profile.limited.declineLabels.message'),
        returnLabel: ContentService.getString('profile.limited.declineLabels.returnLabel')
      };
    }
  }

  $http.get(locationSvc.fetchQuery()).then(function (response) {
    if(response.data.mustFillInProfile) {
      renderView(response);
    }
    else {
      locationSvc.changeView(appSettings.viewLogin);
    }
  });

  vm.handleViewHtml = function () {
    locationSvc.changeView(appSettings.viewHtml);
  };

  vm.updateLanguage = function() {
    var language = vm.model.ISOCountryLanguageGUID;

    if (language) {
      var selectedLanguage = vm.content.language.items.filter(function(item) {
        return item.value === language;
      })[0];

      var questionId1 = vm.model.securityQuestionAnswers[0].id;
      var questionId2 = vm.model.securityQuestionAnswers[1].id;
      var timeZoneCodeId = vm.model.TimeZoneCodeId;

      CommonService.get(selectedLanguage.url).then(function(data){
        renderView(data);
      }).then(function() {
        // this is needed to set values, if ngmodel is bound a value is replaced when incoming data renders
        $timeout(function(){
          $scope.$broadcast("$langugeChanged");

          vm.model.securityQuestionAnswers[0].id = questionId1;
          vm.model.securityQuestionAnswers[1].id = questionId2;
          vm.model.TimeZoneCodeId = timeZoneCodeId;
          vm.model.ISOCountryLanguageGUID = language;
        });
      });
    }
  }

  vm.openLegal = function (url, type) {
    if (type === 'terms') vm.agreeTerms = true;
    if (type === 'privacy') vm.agreePrivacy = true;

    CommonService.get(url).then(function (data) {
      CommonModalService.open({
          content: data.data.content,
          doneButton: ContentService.getString('common.done')
        }, 'legal');
    });
  };

  vm.submit = function() {
    let postUrl = (vm.policyAgreement.agree == 1) ? vm.content.declineUrl : vm.content.acceptUrl;

    if (!vm.policyAgreement.agree || (vm.userForm.$invalid && vm.policyAgreement.agree == 2)) {
      vm.multiError = true;
      uiMessages.showMessage('error', vm.content.validationMessages.multipleInvalid);
      window.scrollTo(0,150);
      $document[0].querySelector('.account-creation-form .ng-invalid').focus();
    } else {
      vm.multiError = false;

      //map model for Password
      vm.model.password = vm.model.password1;

      //map policies accepted
      vm.model.policies = vm.content.siteTerms.data;

      vm.model.mfaCode = vm.mfaCode;
      vm.model.phoneNumberOptIn = vm.phoneNumberOptIn;

      vm.model.redirectUri = $location.search().redirect_uri;
      vm.model.state = $location.search().state;

      vm.submitting = true;
      CommonService.post(postUrl, vm.model).then(function(response){
        vm.submitting = false;
        if (response.data.statusEnum === "Fail") {
          return uiMessages.showMessage('error', response.data.message);
        } else if (response.data.statusEnum == "TempMFARequired") {
          vm.mfaRequired = true;
          return uiMessages.showMessage('info', "Please enter the code sent to your mobile phone.");
        }

        if (vm.policyAgreement.agree == 1) {
          vm.termsConditionsDeclined = true;
          window.scrollTo(0,10);
        } else {
          if(response.data.redirectUri) {
            window.location.assign(response.data.redirectUri);
          }
          else {
            locationSvc.changeView(appSettings.viewSignedIn);
          }
        }
      });
    }
  }

};

profileCtrl.$inject = ['$http', '$timeout', '$document', '$location', '$scope', 'uiMessages', 'appSettings', 'locationSvc', 'CommonService' ,'CommonModalService', 'ContentService'];
