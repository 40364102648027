function formSplashCtrl (HeaderService, ExperienceService, CandidateProcessService, $http, locationSvc) {
  var vm = this;

  const experienceTags = ExperienceService.getCurrentExperienceProcessTags();

  Promise.all([
      HeaderService.getHeaderContent(),
      $http.get(locationSvc.fetchQuery()),
      CandidateProcessService.getAll(experienceTags)
    ]).then(function ([headerContent, processResponse, processList]) {
      vm.accountInfo = headerContent.data.account;
      let sortedProcesses = CandidateProcessService.sortProcesses(processList);
      vm.activeProcess = CandidateProcessService.getCurrentProcess(sortedProcesses);
      vm.process = processResponse.data;
  });
}

export default formSplashCtrl;

formSplashCtrl.$inject = ['HeaderService', 'ExperienceService', 'CandidateProcessService', '$http', 'locationSvc'];
