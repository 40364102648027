<template>
  <ul class="form-progress-bar" v-if="shouldDisplay">
    <li v-for="(form, index) in list" :key="index"
      :class="getProgressClass(form, list[index + 1])"
    ></li>
  </ul>
</template>

<script>
export default {
  name: "form-progress-bar",
  props: {
    list: Array
  },
  computed: {
    shouldDisplay() {
      return this.list && this.list.length > 1
    },
  },
  methods: {
    getProgressClass(form, nextForm) {
      let currentFormClass = this.calculateFormClass(form)

      if (nextForm) {
        const nextFormClass = this.calculateFormClass(nextForm)

        if (nextFormClass === " future" && currentFormClass !== " future") {
          currentFormClass += " last-active"
        }
      }

      return currentFormClass
    },
    calculateFormClass(form) {
      let completedOrFuture =
        form.statusEnum === "Completed" ? " completed" : " future";
      return form.isCurrent
        ? (completedOrFuture += " current")
        : completedOrFuture
    }
  }
}
</script>