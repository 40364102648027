async function autoLoginCtrl($http, $location, appSettings, locationSvc, uiMessages) {
    let autoLoginUrl = appSettings.apiBaseUrl + "Login/Native/autologin";
    let jwt = $location.search().jwt ? 'jwt=' + $location.search().jwt : null;
    let redirect_uri = 'redirect_uri=' + encodeURIComponent(window.location.origin + appSettings.baseUrl + '#' + appSettings.viewSignedIn);

    var params = [jwt, redirect_uri];

    var query = '?' + params.filter(function (param) { return param }).join('&');

    var url = autoLoginUrl + query;

    $http.post(url).then(function (response) {
        if (response.data.statusEnum == "Success") {
            //uiMessages.hideMessage();
            if(response.data.redirectUri) {
                window.location.replace(response.data.redirectUri);
            }
            else {
                locationSvc.changeView(appSettings.viewLogin);
            }
        } else if (response.data.statusEnum == "Fail") {
            uiMessages.showMessage('error', response.data.message);
        }
    });
    uiMessages.showMessage('info','after promise');
  //  window.location.replace(response.data.redirectUrl);
}

export default autoLoginCtrl;

autoLoginCtrl.$inject = ['$http', '$location', 'appSettings','locationSvc','uiMessages'];
