import 'angular-sanitize'; //provides 'ngSanitize' module

import UiMessagesModule from 'modules/uiMessages.module';
import AppSettingsModule from 'common/app-settings.module';
import CommonModule from 'modules/common.module';

import PasswordService from 'services/password.service'; //is this used?
import profileCtrl from 'login/profileCtrl';
import loginCtrl from 'login/loginCtrl';
import logoutCtrl from 'login/logoutCtrl';
import autoLoginCtrl from 'login/autoLoginCtrl';
import firstTimeLoginCtrl from 'login/firstTimeLoginCtrl';
import nativeLoginInitialCtrl from 'login/nativeLoginInitialCtrl';
import nativeLoginDefaultCtrl from 'login/nativeLoginDefaultCtrl';
import nativeLogoutCtrl from 'login/nativeLogoutCtrl';
import loginCallbackCtrl from 'login/loginCallbackCtrl';
import resetCtrl from 'login/resetCtrl';
import securityCtrl from 'login/securityCtrl';
import passwordStrength from 'common/components/password-strength/passwordStrength.directive';
import passwordFields from 'components/password-fields/cbPasswordFields.directive';
import securityQuestionFields from 'components/security-question-fields/cbSecurityQuestionFields.directive';
import declinedTermsConditions from 'login/declinedView.vue';
import signedInCtrl from 'login/signedInCtrl';
import loggedOut from 'login/logged-out.vue';
import logoutView from 'login/logout-view.vue';
import nativeLogoutView from 'login/native-logout-view.vue';
import customLoginConfig from 'login/custom-login-config.vue';
import customLoginConfigCtrl from 'login/customLoginConfigCtrl';

export default angular.module('candidate-login-module', [
    UiMessagesModule,
    AppSettingsModule,
    CommonModule,
    'ngSanitize'
  ])
  .factory('PasswordService', PasswordService)
  .controller('profileCtrl', profileCtrl)
  .controller('loginCtrl', loginCtrl)
  .controller('autoLoginCtrl', autoLoginCtrl)
  .controller('logoutCtrl', logoutCtrl)
  .controller('firstTimeLoginCtrl', firstTimeLoginCtrl)
  .controller('nativeLoginInitialCtrl', nativeLoginInitialCtrl)
  .controller('nativeLoginDefaultCtrl', nativeLoginDefaultCtrl)
  .controller('nativeLogoutCtrl', nativeLogoutCtrl)
  .controller('customLoginConfigCtrl', customLoginConfigCtrl)
  .controller('loginCallbackCtrl', loginCallbackCtrl)
  .controller('signedInCtrl', signedInCtrl)
  .controller('resetCtrl', resetCtrl)
  .controller('securityCtrl', securityCtrl)
  .directive('cbPasswordStrength', passwordStrength)
  .directive('cbPasswordFields', passwordFields)
  .directive('cbSecurityQuestionFields', securityQuestionFields)
  .value('decline-terms-conditions', declinedTermsConditions)
  .value('logged-out', loggedOut)
  .value('logout-view', logoutView)
  .value('native-logout-view', nativeLogoutView)
  .value('custom-login-config', customLoginConfig)
  .name;
