export default function cbValidate ($compile, uiMessages, CommonFormValidation) {
  var link = function(scope, jelement, attrs) {
    let element = jelement[0];
    var vm = this;
    
    function showError(errorElement, html) {
      if(!element.parentNode.querySelector('.cb-error-input')) {
        element.parentNode.appendChild(errorElement);
        $compile(angular.element(errorElement))(scope);
      } else {
        element.parentNode.querySelector('.cb-error-input').innerHTML = html;
      }
    }
    function initErrorMessage(errorText){
      var html = '<strong>'+scope.cbValidate.name+'</strong><p class="margin-sm-T margin-sm-B">'+errorText+'</p>';
      
      let errorElement = document.createElement("DIV");
      errorElement.innerHTML = html;
      errorElement.classList.add('cb-error-input');
      errorElement.classList.add('ng-hide');

      showError(errorElement, html);
    }

    // create library of rules for validation
    //// required, minlenght, maxlength, email, ect.
    function validate(value, hide) {
      var params = {},
          error;

      if(!scope.cbValidate) return;

      if(scope.cbValidate.error){
        if(scope.cbValidate.error.required) {
          params.error = 'required';
        }
        else if (scope.cbValidate.error.minlength) {
          params.error = 'minlength';
        }
        else if (scope.cbValidate.error.maxlength) {
          params.error = 'maxlength';
        }
      }

      for(var key in scope.cbValidate.type) {
        if(scope.cbValidate.type[key].name === 'minlength') {
          params.minlength = parseInt(attrs.minlength);
        }
        if(scope.cbValidate.type[key].name === 'maxlength') {
          params.maxlength = parseInt(attrs.maxlength);
        }
      }

      CommonFormValidation.validate(value, scope.cbValidate.type, element, params).then(function(data) {
        var message = scope.cbValidate.message || data.message;

        // check if ANY validation failed
        if (data.valid) {
          if(element.parentNode.querySelector('.cb-error-input')) {
            element.parentNode.querySelector('.cb-error-input').classList.add('ng-hide');
          }

          if(element.classList.contains('ng-touched')) {
            element.classList.remove('ng-invalid');
          }
        } else {
          initErrorMessage(message);
          if(!hide) {
            if(element.parentNode.querySelector('.cb-error-input')) {
              element.parentNode.querySelector('.cb-error-input').classList.remove('ng-hide');
            }
          }
        }
      })
    }

    let hideErrorEventHandler = function(e) {
      if(element.parentNode.querySelector('.cb-error-input')) {
        element.parentNode.querySelector('.cb-error-input').classList.add('ng-hide');
      }
      validate(e.currentTarget.value, true);
    };
    
    element.addEventListener('blur', hideErrorEventHandler);

    let showEventErrorHandler = function(e) {
      if(element.classList.contains('ng-pristine') && !element.classList.contains('ng-touched')) return;
      validate(e.currentTarget.value);
    };

    element.addEventListener('focus', showEventErrorHandler);
    element.addEventListener('keyup', showEventErrorHandler);
    element.addEventListener('click', showEventErrorHandler);
  };

  return {
    restrict: "A",
    link: link,
    scope: {
      cbValidate:'='
    }
  };
};

cbValidate.$inject = ['$compile', 'uiMessages', 'CommonFormValidation'];
