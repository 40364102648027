export default function resetCtrl ($rootScope, $scope, $http, $q, $document, uiMessages, appSettings, locationSvc, ContentService) {
  var vm = this;
  vm.model = {
    securityQuestionAnswers: [
      { id: '', answer: ''},
      { id: '', answer: ''}
    ]
  };
  vm.password1 = {};
  vm.password2 = {};
  vm.submitting = false;
  vm.mfaRequired = false;
  vm.mobilePhoneRequired = false;

  vm.title = ContentService.getString('headerTitle.message');

  $http.get(locationSvc.fetchQuery()).then(function (data) {
    ContentService.setLocale(data.data.locale);
    vm.content = data.data;
    vm.content.passwordRules.title = ContentService.getString('profile.passwordRules.title');
    vm.content.passwordRulesdoNotMatchMessage = ContentService.getString('profile.passwordRules.doNotMatchMessage');
    vm.content.passwordRules.rules.capital.label = ContentService.getString('profile.passwordRules.rules.capital.label');
    vm.content.passwordRules.rules.lowerCase.label = ContentService.getString('profile.passwordRules.rules.lowerCase.label');
    vm.content.passwordRules.rules.special.label = ContentService.getString('profile.passwordRules.rules.special.label');
    vm.content.passwordRules.rules.number.label = ContentService.getString('profile.passwordRules.rules.number.label');
    vm.content.passwordRules.rules.length.label = ContentService.getString('profile.passwordRules.rules.length.label');
    vm.content.passwordRules.rules.maxLength.label = ContentService.getString('profile.passwordRules.rules.maxLength.label');
    vm.content.submitLabel = ContentService.getString('common.submit');
    vm.content.title = ContentService.getString('profile.reset.title');

    vm.password1 = {
      label: ContentService.getString('profile.passwordLabel'),
      placeholder: data.data.passwordPlaceholder,
      rules: data.data.passwordRules,
      value: ''
    };
    vm.password2 = {
      label: ContentService.getString('profile.confirmPasswordLabel'),
      placeholder: data.data.confirmPlaceholder,
      doNotMatchMessage: ContentService.getString('profile.passwordRules.doNotMatchMessage'),
      value: ''
    };
  }, function(err) {
    console.log(err);
  });

  vm.handleReset = function (e) {
    uiMessages.hideMessage();

    if (vm.resetForm.$invalid) {
      uiMessages.showMessage('error', ContentService.getString('profile.validationMessages.multipleInvalid'));
      window.scrollTo(0,150);

      angular.element($document[0].querySelector('.cb-reset-password-form .ng-invalid')).focus();
      return $q.when([]);
    }
    else {
      vm.submitting = true;

      if (!vm.content.securityQuestionResetRequired){
        vm.model.securityQuestionAnswers = null;
      }

      try {
        const postBody = {
          password: vm.model.password1,
          securityQuestionAnswers: vm.model.securityQuestionAnswers,
          mfaCode: vm.model.mfaCode,
          mobilePhoneNumber: vm.model.mobilePhoneNumber
        };

        return $http.post(vm.content.submit.url, postBody).then(function (data) {
          if (data.data.statusEnum == "Success") {
            uiMessages.hideMessage();
            locationSvc.changeView(appSettings.viewSignedIn);
            uiMessages.showMessage('success', data.data.message);
          }
          else if (data.data.statusEnum == "Fail") {
            uiMessages.hideMessage();
            uiMessages.showMessage('error', data.data.message);
          }
          else if (data.data.statusEnum == "TempMFARequired") {
            vm.mfaRequired = true;
            vm.mobilePhoneRequired = false;
            uiMessages.showMessage('info', "Please enter the code sent to your mobile phone.");
          }
          else if (data.data.statusEnum == "TempMFAMobilePhoneRequired") {
            vm.mobilePhoneRequired = true;
            vm.mfaRequired = false;
            uiMessages.showMessage('info', "A mobile phone number is needed for Multi-Factor Authentication.");
          }
        });
      }
      finally {
        vm.submitting = false;
      }
    }
  };

  vm.exitReset = function(){
    locationSvc.changeView(appSettings.viewLogin);
  };

};

resetCtrl.$inject = ['$rootScope', '$scope', '$http', '$q', '$document', 'uiMessages', 'appSettings', 'locationSvc', 'ContentService'];
