export default function CommonFromValidationService($q) {
  var svc = {};

  svc.validate = function(val, validations, el, params){
    var defer = $q.defer();
    var data = {};
    data.valid = true;

    if(!params.error) {
      defer.resolve(data);
      return defer.promise;
    }

    for(var i in validations) {
      var currentValidation = validations[i];

      // TYPE: Required Validation
      if(currentValidation.name === 'required' && params.error === 'required') {
        if(!val || !val.length && typeof val !== 'object') {
          // if the user hasn't interacted don't continue
          if(el.classList.contains('ng-untouched')) break;
          data.valid = false;
          data.message = currentValidation.message || "This field is required.";
          break;
        }
      }
      // TYPE: MinLength Validation
      if(currentValidation.name === 'minlength' && params.error === 'minlength') {
        if(val && val.length < params.minlength) {
          // if the user hasn't interacted don't continue
          if(el.classList.contains('ng-untouched')) break;
          data.valid = false;
          data.message = currentValidation.message || "This field requires at least " + params.minlength + " characters.";
          break;
        }
      }
      // TYPE: MaxLength Validation
      if(currentValidation.name === 'maxlength' && params.error === 'maxlength') {
        if(val && val.length > params.maxlength) {
          // if the user hasn't interacted don't continue
          if(el.classList.contains('ng-untouched')) break;
          data.valid = false;
          data.message = currentValidation.message || "This field requires at least " + params.maxlength + " characters.";
          break;
        }
      }
    }

    defer.resolve(data);
    return defer.promise;
  }

  return svc;
};

CommonFromValidationService.$inject = ['$q'];
