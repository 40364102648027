/*
 * intercepts XHR requests and manages Authorization headers
 */
import { httpEventBus, HttpEvents } from 'common/http-event-bus';

var AuthorizationInterceptorModule = angular.module("authorizationInterceptorModule", [])
	.config(["$httpProvider", function ($httpProvider) {
		var interceptor = ['$q', '$location', 'AuthService', 'appSettings', function ($q, $location, AuthService, appSettings) {
			var checkAuthorization = function (response) {
				if (response.status === 401 || response.status === 403) {
          response.noAlert = true;
					AuthService.clearSession(response.status);
					httpEventBus.emit(HttpEvents.AuthError, response.status);
					return $q.reject(response);
				}
			};
			return {
				request: function (config) {
					//if (!config.url) //no query string
					//	$location.replace().url(appSettings.viewLogin);
					var hdr = AuthService.getAuthHeader();
					if (hdr) {
						config.headers["Authorization"] = hdr;
					}
					return config;
				},
				response: function (response) {
					checkAuthorization(response);
					var hdr = response.headers("Authorization");
					if (hdr) {
						AuthService.setAuthHeader(hdr);
					}
					return response;
				},
				responseError: function (rejection) {
					checkAuthorization(rejection);
					return $q.reject(rejection);
				}
			}
		}];

		$httpProvider.interceptors.push(interceptor);
	}]);

export default AuthorizationInterceptorModule.name;
