<template>
  <section class="cb-form-splash">
    <cb-splash :title="title" v-if="process"
      :message="message"
      :icon="elementIconPath">
        <div class="cb-form-splash-buttons">
          <button type="button" :title="$t('activities.splash.agreementContinue')" v-if="isDeclined" class="cb-btn secondary" @click="goBackToForm()">
            {{$t('activities.splash.agreementContinue')}}
          </button>
          
          <button type="button" :title="$t('activities.reviewCompleted')" class="cb-btn secondary" @click="goToOverviewAction()">
            {{$t('activities.reviewCompleted')}}
          </button>

          <div class="next-action-container" v-if="!isDeclined && nextProcessIsAvailable">
            <button type="button" :title="$t('activities.splash.startNext')" class="cb-btn primary" @click="goToNextAction()">
              {{$t('activities.splash.startNext')}}
            </button>
          </div>
        </div>
    </cb-splash>
  </section>
</template>

<script>
  import AppSettings from 'appSettings';
  import CbSplash from 'common/components/cb-splash.vue';
  import ActivityCompleteIcon from 'common/assets/icons/activities/activity-set-complete.svg';
  import ActivityDeclinedIcon from 'common/assets/icons/activities/activity-set-declined.svg';
  import ActivityPendingIcon from 'common/assets/icons/activities/activity-set-pending.svg';

  export default {
    name: 'cb-form-splash',
    props: {
      accountInfo: {
        type: Object,
        required: true
      },
      //process that sent the candidate to the splash
      process: {
        type: Object,
        required: true
      },
      activeProcess: {
        type: Object,
        required: true
      }      
    },
    components: {
      CbSplash
    },
    computed: {
      message: function() {
        if(this.isDeclined) {
          return this.$t('activities.splash.declined');
        }
        else if(!this.hasNextProcess) {
          return this.$t('activities.splash.completedNoMoreAvailable');
        }
        else if(this.nextProcessIsAvailable) {
          return this.$t('activities.splash.completedMoreAvailable');
        }
        else {
          return this.$t('activities.splash.stopped');
        }
      },
      title: function() {
        if(this.isDeclined) {
          return this.$t('activities.splash.declinedActivities');
        }
        else if(this.nextProcessIsAvailable) {
          return this.$t('activities.splash.personalizedThanks', this.accountInfo);
        }
        else {
          return this.$t('activities.splash.personalizedHello', this.accountInfo);
        }
      },
      elementIconPath: function () {
        if(this.isDeclined) {
          return this.ActivityDeclinedIconPath;
        }
        else if(this.process.statusEnum === 'Completed') {
          return this.ActivityCompleteIconPath;
        }
        else {
          return this.ActivityPendingIconPath;
        }
      },
      isDeclined: function() {
        return this.process.processSummaryList.filter(step => step.statusEnum === 'Declined').length > 0;
      },
      hasNextProcess: function() {
        return this.process.id !== this.activeProcess.id;
      },
      nextProcessIsAvailable: function() {
        return this.hasNextProcess && (this.activeProcess.statusEnum === 'Pending' || this.activeProcess.statusEnum === 'Declined');
      }
    },
    methods: {
      goToOverviewAction () {
        this.$_cb.router.changeView(
          AppSettings.viewActivitiesOverview,
          null
        )
      },
      goToNextAction () {
        this.$_cb.router.changeView(
          AppSettings.viewHome,
          this.activeProcess.view.url
        )
      },
      async goBackToForm () {        
        const declinedSteps = this.process.processSummaryList.filter(step => step.statusEnum === 'Declined');
        
        this.$_cb.router.changeView(
          AppSettings.viewForms,
          declinedSteps[0].events.view.url
        )
      }
    },
    data () {
      return {
        ActivityCompleteIconPath: '#' + ActivityCompleteIcon.id,
        ActivityDeclinedIconPath: '#' + ActivityDeclinedIcon.id,
        ActivityPendingIconPath: '#' + ActivityPendingIcon.id
      };
    }
  }
</script>

<style scoped lang="scss">
  @import "@clickboarding/style/colors";
  
  $button-spacing: 1.5rem;
  
  .cb-form-splash {
    background: $cb-white;
    text-align: center;
    padding: 1.5rem;
  }

  .cb-form-splash-buttons {
    display: flex;
    flex-direction: column;
    
    .next-action-container {
      border-top: 1px solid $cb-dark-grey-6;
      padding-top: $button-spacing;
    }
    button {
      width: 100%;
    }
    button:not(:last-child) {
      margin-bottom: $button-spacing;
    }
  }
</style>
