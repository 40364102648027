import { IconProvider } from 'common/icon.module';

export default function cbUiMessagesDirective (uiMessages) {
	var ctrl = function ($scope, $element, $attrs) {
		var vm = this;

		$scope.$watch(function () { return uiMessages.obj; }, function (newval, oldval) {
			vm.show = newval.visible;
			vm.template = newval.template;
			vm.message = newval.message;
		}, true);

		vm.getIconPath = function (iconName) {
			return IconProvider.getPath(iconName);
		};

		vm.hide = function () {
			uiMessages.hideMessage();
		};
	};

	return {
		restrict: "E",
		replace: true,
		controller: ["$scope", "$element", "$attrs", ctrl],
		controllerAs: 'vm',
		template: require('components/ui-messages/templates/message.html'),
		scope: {
		}
	};
};

cbUiMessagesDirective.$inject = ['uiMessages'];