import analyticsService from 'common/services/analytics.service';
import { USER_SIGNED_IN } from 'analytics/event-types';
import appInsightsAnalytics from 'analytics/app-insights-analytics';
import store from '../store';

async function signedInCtrl ($http, $location, appSettings, locationSvc, HeaderService, ExperienceService, CandidateProcessService) {
  let signedInUrl = appSettings.apiBaseUrl + "signedIn";
  let referrerUrl = ($location.search().ref ? "?ref=" + $location.search().ref : "");
  signedInUrl = signedInUrl + referrerUrl;

  var signedInResponse = await $http.get(signedInUrl);
  analyticsService.track(USER_SIGNED_IN, {});
  
  if (signedInResponse.data.hasUnacceptedPolicies) {
    analyticsService.identify(signedInResponse.data.userId);
    locationSvc.changeView(
        appSettings.viewProfile,
        signedInResponse.data.destUrl
    );
  } else {
    HeaderService.setHeaderEndpoint(signedInResponse.data.headerUrl);
    await HeaderService.refreshHeaderData();
    var data = await HeaderService.getHeaderData();

    analyticsService.identify(signedInResponse.data.userId, { locale: data.locale, tenant_id: data.tenantId });
    appInsightsAnalytics.setAuthenticatedUserId(signedInResponse.data.userId, data.clientApiIdentifier);
    store.commit('features/setFeatureFlags', data.clientFeatureFlags, { root: true });

    var processTags = ExperienceService.getCurrentExperienceProcessTags();
    var filteredProcesses = await CandidateProcessService.getAll(processTags);
    var activeProcess = CandidateProcessService.getCurrentProcess(
        filteredProcesses
    );

    if (signedInResponse.data.referrerUrl) {
      window.location.replace(signedInResponse.data.referrerUrl);
    } else if (activeProcess && activeProcess.view && activeProcess.view.url &&
        (activeProcess.statusEnum === 'Pending' || activeProcess.statusEnum === 'Declined' || filteredProcesses.length === 1)) {
      HeaderService.setHeaderEndpoint(activeProcess.processHeaderUrl);
      await HeaderService.refreshHeaderData();

      locationSvc.changeView(appSettings.viewProcess, activeProcess.view.url);
    } else {
      locationSvc.changeView(appSettings.viewActivitiesOverview);
    }
  }
};

export default signedInCtrl;

signedInCtrl.$inject = ['$http', '$location', 'appSettings', 'locationSvc', 'HeaderService', 'ExperienceService', 'CandidateProcessService'];
