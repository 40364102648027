import RoseFormService from 'common/services/roseForm.service';

export default function roseForm($http, appSettings, $ocLazyLoad, uiMessages) {
  var linker = function (scope, element, attrs) {
    if (!document.querySelector('link#roseCss')) {
      let innerElement = document.createElement("LINK");
      innerElement.setAttribute('id', 'calendarCss');
      innerElement.setAttribute('href', appSettings.cdnBaseUrl+ '/v2/rose/Calendar.css');
      innerElement.setAttribute('rel', 'stylesheet');

      document.querySelector('head').appendChild(innerElement);
    }

    var roseElements = document.getElementsByClassName('rose');
    
    var observer = new MutationObserver(function(mutations) {
      RoseFormService.applyCustomDomManipulation(roseElements[0]);
      observer.takeRecords(); //clears queue, we have no need to let them build up
    });
    
    if(roseElements.length) {
      observer.observe(roseElements[0], {
        subtree: true,
        childList: true,
        attributes: false,
        characterData: false
      });
    }

    RoseFormService.setUiMessages(uiMessages);
    RoseFormService.dojoInit(pageWatch, scope.currentPage.eventUrl, appSettings.baseUrl);

    function pageWatch() {
      scope.$watch('currentPage', function () {
        RoseFormService.rosePDF = null;
        if (typeof scope.currentPage != 'undefined') {
          if (typeof scope.currentPage.eventUrl !== "undefined") {
            RoseFormService.setEventUrl(scope.currentPage.eventUrl);
          }
          if (typeof scope.currentPage.contentUrl !== "undefined") {
            $http.get(scope.currentPage.contentUrl).then(function (data) {

              RoseFormService.createEvent(data.data).then(function (eventData) {
                // add event listners
                window.addEventListener('rose:formRendered', function(e){
                  RoseFormService.renderHandler(e);
                }, false);
                window.addEventListener("rose:formData", RoseFormService.dataHandler, false);

                RoseFormService.dispatchEvent("rose:formStart", eventData);
                RoseFormService.setDataCallback(function (data) {
                  scope.model.nextSuccess(data.data);
                });

                scope.model.pageRendered = true;
              });
            });
          }
        }
      });
    }

    scope.$watch(function(){ return RoseFormService.rosePDF; }, function(newval) {
      import('common/components/pdf/cb-pdf-viewer-loader').then(pdfLoader => {
        pdfLoader.load().then(pdfViewerData => {
          scope.pdfViewerLabels = pdfViewerData.labels;

          $ocLazyLoad.load(pdfViewerData.module).then(() => {
            scope.pdfUrl = newval;
          })
        });
      });      
    });

    scope.$on('cbFormCtrl:nextClick', function(e) {
      RoseFormService.dispatchEvent("rose:formGatherData", null);
    });

    scope.$on('$destroy', function () {
      window.removeEventListener('rose:formData', RoseFormService.dataHandler);
      window.removeEventListener('rose:formRendered', RoseFormService.renderHandler);
      observer.disconnect();
    });
  };

  return {
    restrict: "E",
    replace: true,
    link: linker,
    template: require('rose/rose.html'),
    scope: {
      currentPage: '=',
      model: '='
    }
  };
};

roseForm.$inject = ['$http', 'appSettings', '$ocLazyLoad', 'uiMessages'];
