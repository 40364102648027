import UAParserLib from 'ua-parser-js'; // provides User Agent Parser
import unsupportedController from 'general/unsupportedCtrl';
import UnsupportedView from "general/unsupported-view";

export default angular.module('unsupportedBrowser', [ ])
	.factory('uaParser', function(){
		return new UAParserLib();
	})
	.value('unsupported-view', UnsupportedView)
	.controller('unsupportedCtrl', unsupportedController)
	.name;