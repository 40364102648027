import { IconProvider } from 'common/icon.module';

export default function cbPasswordStrength ($compile, uiMessages, PasswordService) {
  var link = function (scope, jelement, attrs, ctrl) {
    let element = jelement[0];
    var vm = this;
    var pwStrength;
    var clean = true;

    function setValidationClass(valid) {
      ctrl.$setValidity("passwordStrength", valid);
    }

    function showError(errorElement, html, cb) {
      if (!element.parentNode.querySelector('.cb-pw-strength')) {
        element.parentNode.appendChild(errorElement);
        $compile(angular.element(errorElement))(scope);
      } else {
        element.parentNode.querySelector('.cb-pw-strength').innerHTML = html;
      }

      if (cb) cb();
    }

    element.addEventListener('blur', function (e) {
      setValidationClass(PasswordService.isValid());

      element.parentNode.querySelector('.cb-pw-strength').classList.add('ng-hide');
    });
    element.addEventListener('focus', function (e) {
      var cb = function () {
        element.parentNode.querySelector('.cb-pw-strength').classList.remove('ng-hide');
      };
      validPassword(scope.pw, cb);
    });

    function getIcon(valid) {
      var xIconPath = IconProvider.getPath('Ui-X-Icon');
      var useClass = valid ? 'cb-icon-x-icon-success' : 'cb-icon-x-icon-error';
      return '<svg class="cb-icon-x-icon"><use class="' + useClass + '" xlink:href="' + xIconPath + '"></use>';
    }

    function validPassword(newVal, cb) {
      if (newVal && newVal.length > 0) {
        clean = false;
        let passwordStrength = element.parentNode.querySelector('.cb-pw-strength');
        if(passwordStrength) {
          passwordStrength.classList.remove('clean-true');
          passwordStrength.classList.add('clean-false');
        }        
      } else {
        PasswordService.setInvalid();
        clean = true;
        let passwordStrength = element.parentNode.querySelector('.cb-pw-strength');
        if(passwordStrength) {
          passwordStrength.classList.remove('clean-false');
          passwordStrength.classList.add('clean-true');
        }        
      }

      PasswordService.validate(newVal);

      if (PasswordService.validations.valid) {
        let passwordStrength = element.parentNode.querySelector('.cb-pw-strength')
        if(passwordStrength) {
          passwordStrength.classList.remove('valid-false');
          passwordStrength.classList.add('valid-true');
        }
      } else {
        let passwordStrength = element.parentNode.querySelector('.cb-pw-strength');
        if(passwordStrength) {
          passwordStrength.classList.remove('valid-true');
          passwordStrength.classList.add('valid-false');
        }        
      }

      setValidationClass(PasswordService.validations.valid);

      var strengthList = '<ul>\
        <li class="item-'+ PasswordService.validations.capital + '"><span class="icon">' + getIcon(PasswordService.validations.capital) + '</span>' + vm.rules.capital.label + '</li>\
        <li class="item-'+ PasswordService.validations.lower + '"><span class="icon">' + getIcon(PasswordService.validations.lower) + '</span>' + vm.rules.lowerCase.label + '</li>\
        <li class="item-'+ PasswordService.validations.special + '"><span class="icon">' + getIcon(PasswordService.validations.special) + '</span>' + vm.rules.special.label + '</li>\
        <li class="item-'+ PasswordService.validations.number + '"><span class="icon">' + getIcon(PasswordService.validations.number) + '</span>' + vm.rules.number.label + '</li>\
        <li class="item-'+ PasswordService.validations.minlength + '"><span class="icon">' + getIcon(PasswordService.validations.minlength) + '</span>' + vm.rules.length.label + '</li>\
        <li class="item-'+ PasswordService.validations.maxlength + '"><span class="icon">' + getIcon(PasswordService.validations.maxlength) + '</span>' + vm.rules.maxLength.label + '</li>\
      </ul>';

      // build the DOM object
      let html = '<p class="margin-none"><strong>' + vm.headerMessage + ':</strong></p>' + strengthList;      
      let pwStrengthElement = document.createElement("DIV");
      pwStrengthElement.classList.add("cb-pw-strength");
      pwStrengthElement.classList.add("ng-hide");
      pwStrengthElement.classList.add(`clean-${clean}`);
      pwStrengthElement.classList.add(`valid-${PasswordService.validations.valid}`);
      pwStrengthElement.innerHTML = html;

      showError(pwStrengthElement, html, cb);
    }

    var getRules = scope.$watch('rules', function (newval, oldval) {
      if (!newval) return;

      vm.headerMessage = newval.title;
      vm.rules = {};
      vm.rules = newval.rules;

      validPassword(scope.pw);
    });

    scope.$watch('ngModel', function (newval) {
      if (!vm.rules) return;
      validPassword(newval);
    });
  };

  return {
    require: 'ngModel',
    restrict: "A",
    link: link,
    scope: {
      pw: '=',
      rules: '=',
      ngModel: '='
    }
  };
};

cbPasswordStrength.$inject = ['$compile', 'uiMessages', 'PasswordService'];
