<template>  
  <section v-show="model.pageRendered" style="overflow:auto;">
      <div class="formI9">
        <ui-messages></ui-messages>
        <iframe id="i9Frame"
            name="i9Frame"
            class="lg-step-1"
            src="app/forms/formI9Inner.html"
            frameborder="0"></iframe>
      </div>
  </section>
</template>

<script>
import UiMessages from "../components/ui-messages/ui-messages.vue";
import FormNavigationService from 'common/forms/form-navigation.service';
import {mapActions} from 'vuex';
import debounce from 'lodash.debounce';

export default {
  name: "form-i9",
  components: {
    UiMessages
  },
  data() {
    return {
      frameClass: null,
      content: null,
      formMetaData: {
        isValid: true,
        currentStep: 1,
        totalSteps: 4, //default if they haven't added additional preparers
        isFirstStep: true,
        isLastStep: false
      },
      currentWidth: null
    }
  },
  props: {
   currentPage: {
      required: false,
      type: Object  
    },
    form: {
      required: false,
      type: Object
    },
    model: {
      required: false,
      type: Object
    },
    onNextSuccess: {
      required: false,
      type: Function
    }
  },
 
  methods: {
    ...mapActions('ui-messages', ['showMessage', 'hideMessage']),
    loadiFrameData () {
      if (!this.model.frameLoaded){
        console.log("form-i9.vue loadiFrameData postMessage sendData");
        this.$el.querySelector('#i9Frame').contentWindow.postMessage(JSON.stringify({ event: 'sendData', content: this.content }), window.location.protocol + '//' + window.location.host);
        this.model.pageRendered = true;
      }
      else {
        setTimeout(this.loadiFrameData, 500);
      }
    },
    updateFormMetaData(newData) {
      for(var metaDataKey in this.formMetaData) {
        if(metaDataKey in newData) {
          this.formMetaData[metaDataKey] = newData[metaDataKey];
        }
      }
    },
    isLastPage() {
      return this.formMetaData.isLastStep;
    },
    isFirstPage() {
      return this.formMetaData.isFirstStep;
    },
    backButtonDisabled() {
      return this.isFirstPage() || this.isLastPage();
    },
    getNextText() {
      return this.isLastPage() ? "Submit" : "Next";
    },
    goBack() {
      this.$el.querySelector('#i9Frame').contentWindow.postMessage(JSON.stringify({ event:'onBack' }), this.content.baseUrl);
    },
    i9FrameLoaded() {
      console.log("form-i9.vue i9FrameLoaded");
      this.model.frameLoaded = true;
    },
    setFrameClass(force){
      var newWidth = window.document.documentElement.clientWidth;
      if(force || currentWidth !== newWidth) {
        var iframeContainerElement = this.$el.querySelector('div.formI9');
        var containerWidth = iframeContainerElement.clientWidth;
        
        //determine bootstrap breakpoint based on container width
        var bootstrapPrefix = containerWidth >= 1200 ? "lg"
            : containerWidth >= 992 ? "md"
            : containerWidth >= 768 ? "sm"
            : "xs";
        
        //also assign class with bootstrap breakpoint and step number to the iframe (i.e xs-step-1, md-step-1, etc)
        this.frameClass = bootstrapPrefix + "-step-" + this.formMetaData.currentStep;
        this.currentWidth = newWidth;
      }
    },
    save() {
      console.log("form-i9.vue save start");
      var eventName = this.isLastPage() ? 'onSubmit' : 'onNext';
      document.querySelector('#i9Frame').contentWindow.postMessage(JSON.stringify({ event: eventName }), this.content.baseUrl);
      console.log("form-i9.vue save finish - event: "+eventName);
    },
    async loadData() {
        console.log("form-i9.vue loadData start");
        this.model.frameLoaded = false;
      this.model.isLastPage = false;
      this.frameClass = "lg-step-1";
      if (typeof this.currentPage !== "undefined") {
        const data = await this.$http.get(this.currentPage.contentUrl);
        this.nextText = "Next";
        this.content = data.data;
            //cfpLoadingBar.start();
        setTimeout(this.loadiFrameData, 500);//race condition, check if loaded yet
      }
    },
    async i9MessageHandler(e) {
      console.log("form-i9.vue i9MessageHandler start");
      this.hideMessage();
      this.model.isLastPage = false;
      var message = await JSON.parse(e.data);
      this.updateFormMetaData(message);
      this.backDisabled = this.backButtonDisabled();
      this.nextText = this.getNextText();
        this.model.isLastPage = this.isLastPage();
        console.log("form-i9.vue i9MessageHandler event: " + message.event);

      switch (message.event) {
        case 'onFormLoadResult':
          //cfpLoadingBar.complete();
          break;
        case 'onEditStart':
          // does not appear to ever fire
          break;
        case 'onBackStart':
        case 'onNextStart':
        case 'onSubmitStart':
        case 'onUserInfoResult':
          break;
        case 'onEditResult':
          // edit takes you back to the first page
          window.scrollTo(0, 0);
          break;
        case 'onBackResult':
          window.scrollTo(0, 0);
          break;
        case 'onNextResult':
          if (!this.formMetaData.isValid) {
            this.showMessage('error','There are one or more problems with the data entered, you will need to fix the data marked with a red flag in order to continue.');
          }
          window.scrollTo(0, 0);
          break;
        case 'onSubmitResult':
          console.log("form-i9.vue i9MessageHandler onSubmitResult");
            if (!this.formMetaData.isValid) {
            this.showMessage('info','You will need to agree to electronically sign the document in order to continue.');
            window.scrollTo(0, 0);
          } else {
            await this.$http.post(this.currentPage.eventUrl).then(function (response) {
                console.log("form-i9.vue i9MessageHandler onSubmitResult post to this.currentPage.eventUrl nextSuccess");
                this.model.nextSuccess(response.data);
            });
          }
          break;
      }
      this.setFrameClass(true);
    }
  },
  async mounted() {
    FormNavigationService.setNextClickHandler(this.save);
    await this.loadData();
    this.$el.querySelector('#i9Frame').addEventListener('load', this.i9FrameLoaded);
    this.currentWidth = window.document.documentElement.clientWidth;
    var windowResizeEventHandler = debounce(this.setFrameClass, 100);
    window.addEventListener("resize", windowResizeEventHandler);
    this.model.pageRendered = true;
  },
  created: function() {
    window.addEventListener("message", this.i9MessageHandler);
  },
  destroyed: function() {
    FormNavigationService.deleteNextClickHandler();
  }
};
</script>