<template>
  <section class="bg-white activity-overview" v-if="content">
      <div class="text-c">
        <h1 class="headline"  v-if="content.title">{{content.title}}</h1>
        <svg class="cb-icon cb-icon-process-view-title">
          <use v-bind="{'xlink:href': iconPath.labelHeader }"></use>
        </svg>
      </div>
      <div class="h-line slim padding-med-T"></div>
      <div class="content padding-med">
        <ui-messages></ui-messages>
      </div>
      <div class="active-process" v-if="content.activeProcess">
        <div class="cp-tiles text-c">
          <div class="tile" 
          v-bind:class="{'declined': isActiveProcessDeclined,  'active': !isActiveProcessDeclined}">
            <div class="content bg-white">
              <span class="cb-triangle-down marker"></span>
              <h2 class="headline2">{{content.activeProcess.name}}</h2>
              <svg class="cb-icon cb-icon-activity">
                <use v-bind="{'xlink:href': activeProcessIconPath }"></use>
              </svg>
              <div class="padding-sm-T description">{{content.activeProcess.status.label}}: {{content.activeProcess.status.value}}</div>
              <button class="cb-btn primary" 
                v-on:click="takeAction(content.activeProcess.view.url)"
              >
                {{content.activeProcess.view.label}}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="activity-overview-process-type-container stopped-process" v-if="content.stopped">
        <strong class="activity-overview-process-type-container-title">{{content.stopped.header}}</strong>
        <ul class="activity-overview-list">
          <li v-for="(item, index) in content.stopped.processes" :key="index"  class="activity-overview-list-item" >
            <div class="activity-overview-list-item-col1">
              <svg class="cb-icon cb-icon-process-status stopped">
                <use v-bind="{'xlink:href': iconPath.pending }"></use>
              </svg>
              <div>
                {{item.name}}
              </div>
            </div>
            <div class="activity-overview-list-item-col2 hide-xs-down">
              {{item.status.label}}: {{item.status.value}}
            </div>
            <div class="activity-overview-list-item-col3">
              <a href="" class="link" @click.prevent="takeAction(item.view.url)">
                <span class="hide-xs-down">{{item.view.label}}</span>
                <svg class="cb-icon cb-icon-generic-arrow padding-sm-L">
                  <use v-bind="{'xlink:href': iconPath.nextBtn }"></use>
                </svg>
              </a>
            </div>
          </li>
        </ul>
      </div>
      <div class="activity-overview-process-type-container upcoming-process" v-if="content.pending">
        <strong class="activity-overview-process-type-container-title">{{content.pending.header}}</strong>
        <ul class="activity-overview-list">
          <li v-for="(item, index) in content.pending.processes" :key="index"  class="activity-overview-list-item" >
            <div class="activity-overview-list-item-col1">
              <svg class="cb-icon cb-icon-process-status pending">
                <use v-bind="{'xlink:href': iconPath.pending }"></use>
              </svg>
              <div>
                {{item.name}}
              </div>
            </div>
            <div class="activity-overview-list-item-col2 hide-xs-down">
              {{item.status.label}}: {{item.status.value}}
            </div>
            <div class="activity-overview-list-item-col3">
              <a class="link">
                <span class="hide-xs-down">{{item.view.label}}</span>
                <svg class="cb-icon cb-icon-generic-arrow padding-sm-L">
                  <use v-bind="{'xlink:href': iconPath.nextBtn }"></use>
                </svg>
              </a>
            </div>
          </li>
        </ul>
      </div>
      <div class="activity-overview-process-type-container completed-process" v-if="content.completed">
        <strong class="activity-overview-process-type-container-title">{{content.completed.header}}</strong>
        <ul class="activity-overview-list">
          <li v-for="(item, index) in content.completed.processes" :key="index"  class="activity-overview-list-item" >
            <div class="activity-overview-list-item-col1">
              <svg class="cb-icon cb-icon-process-status complete">
                <use v-bind="{'xlink:href': iconPath.completed }"></use>
              </svg>
              <div>
                {{item.name}}
              </div>
            </div>
            <div class="activity-overview-list-item-col2 hide-xs-down" v-if="item.status">
              {{item.status.label}}: {{item.status.value}}
            </div>
            <div class="activity-overview-list-item-col3">
              <a href="" @click.prevent="takeAction(item.view.url)" class="link">
                <span class="hide-xs-down" v-if="item.view">{{item.view.label}}</span>
                <svg class="cb-icon cb-icon-generic-arrow padding-sm-L">
                  <use v-bind="{'xlink:href': iconPath.nextBtn }"></use>
                </svg>
              </a>
            </div>
          </li>
        </ul>
      </div>
      <div class="text-c padding-med margin-c" v-if="!hasProcesses">
        <svg class="cb-icon cb-icon-no-results lg hide-xs-down margin-c">
          <use v-bind="{'xlink:href': iconPath.noResults }"></use>
        </svg>
        <svg class="cb-icon cb-icon-no-results sm show-xs-down margin-c">
          <use v-bind="{'xlink:href': iconPath.noResults }"></use>
        </svg>
      </div>
  </section>  
</template>

<script>

import AppSettings from "appSettings";
import { candidateProcessService , experienceService , uiMessages} from 'modules/common.module.js';
import rightArrowIcon from "@clickboarding/assets/icons/right-arrow.svg";
import myActivitiesTitleIcon from "common/assets/icons/activity-title/my-activities-title-icon.svg";
import activitySetActiveIcon from "common/assets/icons/activities/activity-set-active.svg";
import messagePendingIcon from "@clickboarding/assets/icons/messages/pending.svg";
import messageSuccessIcon from "@clickboarding/assets/icons/messages/success.svg";
import activitySetDeclined from "common/assets/icons/activities/activity-set-declined.svg";
import noResultsFound from "common/assets/icons/general/no-results-found.svg";
import { i18n } from "common/lang-config";
import UiMessages from 'components/ui-messages/ui-messages.vue';
import {mapActions} from 'vuex'

export default {
  name: "activities-overview",
  components: {
    UiMessages
  },
  data() {
    return {      
      content: null,
      hasProcesses: null
    }    
  },
  methods: {
    ...mapActions('ui-messages', ['showMessage', 'hideMessage']),
    takeAction (url) {
      this.$_cb.router.changeView(AppSettings.viewHome, url);
    },
    async groupProcesses(processes) {
      var processGroups = {
        activeProcess: null,
        completed: null,
        declined: null,
        stopped: null,
        pending: null
      };

      var currentProcess = await candidateProcessService.getCurrentProcess(
        processes
      );
      var foundFirstPendingProcess = false;

      for (var i = 0; i < processes.length; i++) {
        var process = processes[i];
        var isActiveProcess = (process.id === currentProcess.id && (currentProcess.statusEnum === 'Pending' || currentProcess.statusEnum === 'Declined'));

        if(isActiveProcess) {
          processGroups.activeProcess = process;

          if(process.statusEnum === 'Declined') {
            process.view.label = i18n.t("activities.continueButton");
          }
          else if(process.statusEnum === 'Pending') {
            process.view.label = i18n.t("activities.startNextButton");
          }
        }
        else {
          switch (process.statusEnum) {
            case "Completed":
              process.view.label = i18n.t("activities.reviewButton");
              processGroups.completed = processGroups.completed || {
                header: i18n.t("activities.completedLabel"),
                processes: []
              };
              processGroups.completed.processes.push(process);
              break;
            case "Declined":
              process.view.label = i18n.t("activities.continueButton");
              processGroups.declined = processGroups.declined || {
                header: i18n.t("activities.declinedLabel"),
                processes: []
              };          
              processGroups.declined.processes.push(process);
              break;
            case "Stopped":          
              process.view.label = i18n.t("activities.reviewButton");
              processGroups.stopped = processGroups.stopped || {
                header: i18n.t("activities.stoppedLabel"),
                processes: []
              };            
              processGroups.stopped.processes.push(process);
              break;
            case "Pending":          
              processGroups.pending = processGroups.pending || {
                header: i18n.t("activities.upNextLabel"),
                processes: []
              };
    
              process.view.label = foundFirstPendingProcess
              ? i18n.t("activities.pendingLabel")
              : i18n.t("activities.upNextLabel");

              process.view.url = null;
              processGroups.pending.processes.push(process);
              foundFirstPendingProcess = true;
              break;
          }
        }
      }
      return processGroups;
    },
    async fetchData(){
      var processTags = experienceService.getCurrentExperienceProcessTags();
      var contentData,
      isActiveProcessDeclined,
      activeProcessIconPath;
     
      const processes = await candidateProcessService.getAll(processTags);      
      contentData = await this.groupProcesses(processes);
      contentData.title = i18n.t("activities.title");

      isActiveProcessDeclined =
        contentData.activeProcess &&
        contentData.activeProcess.statusEnum === "Declined";
      activeProcessIconPath = isActiveProcessDeclined
        ? this.iconPath.declined
        : this.iconPath.nextProcess;

      var hasProcesses = processes && processes.length;
      if (!hasProcesses) {
        var candidateExperience = experienceService.getCurrentExperience();
        var portalNamePath = experienceService.getExperienceStringPath(
          candidateExperience,
          "portalName"
        );
        this.showMessage(
          "info",
          i18n.t("activities.noProcessAssigned", {
            portalName: i18n.t(portalNamePath)
          })
        );
      }

      this.content = contentData;
      this.isActiveProcessDeclined = isActiveProcessDeclined;
      this.activeProcessIconPath = activeProcessIconPath;
      this.hasProcesses = hasProcesses
    }
  },
  computed: {
    iconPath () {
      return ( {
        labelHeader: "#" + myActivitiesTitleIcon.id,
        nextProcess: "#" + activitySetActiveIcon.id,
        nextBtn: "#" + rightArrowIcon.id,
        pending: "#" + messagePendingIcon.id,
        completed: "#" + messageSuccessIcon.id,
        declined: "#" + activitySetDeclined.id,
        noResults: "#" + noResultsFound.id
      });
    }  
  },    
  async beforeMount () {
    await this.fetchData();
  }
  
}
</script>
<style scoped lang="scss">
@import "@clickboarding/style/colors";

.status-icon {
  text-align: center;
  padding: 1.5rem;
}

</style>