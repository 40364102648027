<template>
  <section class="apply-global-styles">
    <section class="padding-med bg-white error-view">
      <div class="text-c content margin-c">
        <h3 class="margin-med-B">{{ errorData.title }}...</h3>
        <svg class="cb-icon" width="240" height="240">
          <use :xlink:href="iconPaths.error"></use>
        </svg>

        <div class="message">{{ errorData.message }}</div>
        <div v-show="errorData.code" class="padding-med-T"><strong>Error Code: {{ errorData.code }}</strong></div>
      </div>

      <div class="tech-support container-fluid">
        <div class="row">
          <strong class="col-12">{{ errorData.support.title }}</strong>
        </div>
        <div class="row h-line"></div>
        <div class="row">
          <div class="col-12 col-sm-6 contact-methods">
            <div class="vert-c">
              <svg class="cb-icon cb-icon-contact">
                <use :xlink:href="iconPaths.phone"></use>
              </svg>
              <a :href="`tel:${errorData.support.phone}`" class="tel">{{ errorData.support.phone }}</a>
            </div>
            <div class="vert-c padding-sm-T">
              <svg class="cb-icon cb-icon-contact">
                <use :xlink:href="iconPaths.email"></use>
              </svg>
              <a :href="`mailto:${errorData.support.email}`">{{ errorData.support.email }}</a>
            </div>

            <div class="h-line show-xs-down"></div>
          </div>

          <div class="col-12 col-sm-6">
            <div>
              {{ errorData.support.hours.label }}: {{ errorData.support.hours.value }}
            </div>
            <div class="padding-sm-T" v-show="navigator.browser">
              {{ errorData.support.systemLabel }}: {{ navigator.browser.name }} {{ navigator.browser.version }} <br/>
              {{ navigator.os.name }} {{ navigator.os.version }}
            </div>
          </div>
        </div>
      </div>

      <div class="margin-med text-c d-flex">
        <button class="cb-btn primary flex-fill" @click="handleDone()">
          {{ errorData.btnLabel }}
        </button>
      </div>
    </section>
  </section>
</template>

<script>
import appSettings from "../appSettings";
import { IconProvider } from 'common/icon.module';
import { commonErrorService } from "modules/error.module";
import DeviceDetector from "ua-parser-js";

export default {
  name: "error-view",
  data() {
    return {
      navigator: null,
      errorData: commonErrorService.getErrorData(),
      iconPaths: {
        error: IconProvider.getPath('Hard-Error-Icon'),
        email: IconProvider.getPath('contact-email-icon'),
        phone: IconProvider.getPath('contact-phone-icon')
      }
    }
  },
  methods: {
    handleDone() {
      this.$_cb.router.changeView(appSettings.viewLogin)
    }
  },
  created () {
    let deviceDetector = new DeviceDetector();
    this.navigator = deviceDetector.getResult();
  }
}
</script>

<style scoped>

</style>