import store from 'store';

export default function nativeLoginDefaultCtrl (appSettings, locationSvc, uiMessages, AuthService, $location, $http, ContentService) {
	AuthService.clearSession();

	var vm = this;
	vm.model = {};

	vm.headerTitle = ContentService.getString('headerTitle.message');
	vm.mfaRequired = false;

	vm.handleLogin = function () {
		let redirect_uri = $location.search().redirect_uri;
		let state = $location.search().state;
		let host = window.location.origin;

		var credentials = {
			username: vm.model.userName.trim(),
			password: vm.model.password,
			mfaCode: vm.model.mfaCode,
			redirectUri: redirect_uri,
			state: state,
			host: host
		};

		if(!vm.model.userName) return uiMessages.showMessage('error', "Please enter your Username.");
		if(!vm.model.password) return uiMessages.showMessage('error', "Please enter your Password.");
		if(vm.mfaRequired && !vm.model.mfaCode) return uiMessages.showMessage('error', "Please enter your MFA code.");

		return $http.post(appSettings.apiBaseUrl + 'login/native', credentials).then(async function (response) {
			if (response.data.statusEnum == "Success") {
				await store.dispatch('auth/setAuthenticatedUserId', response.data.userId);
				
				uiMessages.hideMessage();
				if(response.data.redirectUri) {
					window.location.assign(response.data.redirectUri);
				}
				else {
					locationSvc.changeView(appSettings.viewLogin);
				}
			} else if (response.data.statusEnum == "Fail") {
				uiMessages.showMessage('error', response.data.message);
			} else if (response.data.statusEnum == "TempMFARequired") {
				uiMessages.showMessage('info', "Please enter the code sent to your mobile phone.");
				vm.mfaRequired = true;
			}
		});
	};
	vm.handleForgot = function (e) {
		uiMessages.hideMessage();
		if (!vm.model.userName) {
			uiMessages.showMessage('error', "Please enter your Username.");
		} else {
			return $http.post(appSettings.apiBaseUrl + 'login/native/forgotpassword', { "username": vm.model.userName.trim() }).then(function (response) {
				uiMessages.showMessage('info', response.data.message);
			});
		}
	};
};

nativeLoginDefaultCtrl.$inject = ['appSettings', 'locationSvc', 'uiMessages', 'AuthService', '$location', '$http', 'ContentService'];
