<template>
  <section class="logged-out">
    <cb-splash
      :title="$t('login.loggedOut.title')"
      :icon="loggedOutIcon"
      :message="$t('login.loggedOut.message')"
    >
    <div class="sign-in-container">
      <button class="cb-btn primary sign-in-button" @click="signIn()">
        {{$t('login.loggedOut.signInButton')}}
      </button>
    </div>
    </cb-splash>
  </section>
</template>

<script>
import AppSettings from 'appSettings';
import LoggedOutIcon from 'common/assets/icons/status/access-denied.svg';
import CbSplash from 'common/components/cb-splash.vue';

export default {
  name: "logged-out",
  components: {
      CbSplash
  },
  computed: {
    loggedOutIcon: function() {
      return "#" + LoggedOutIcon.id
    }
  },
  methods: {
    signIn () {
      this.$_cb.router.changeView(AppSettings.viewLogin, null);
    }
  }
};
</script>
<style scoped lang="scss">
@import "@clickboarding/style/colors";

.logged-out {
  background-color: $cb-white;
  padding: 1rem 2rem 2rem;
}

.sign-in-container {
  display: flex;
  justify-content: center;
}

.sign-in-button {
  flex: 1 1 auto;
}
</style>