export default function formHeader () {
	var linker = function (scope, element, attrs) {
		scope.getProgressClass = function (currentIndex, currentForm, formCount) {
			var css;
			if (currentIndex < currentForm) {
				css = 'completed';
				if (currentIndex == currentForm - 1) //the one before the current one since the current one doens't have color
					css += ' current';
			}
			if (currentIndex > currentForm)
				css = 'future';
			return css;
		}

		scope.range = function (n) {
			return new Array(n);
		};
	}
	return {
		restrict: "E",
		replace: true,
		link: linker,
		template: require('forms/formHeader.html'),
		scope: {
			currentFormName:'=',
			currentFormIndex:'=',
			formCount: '='
		}
	};
};

formHeader.$inject = [];