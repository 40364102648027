export default function CommonService ($http, appSettings) {

	var svc = {};

	svc.get = function (url) {
		return $http.get(url);
	};
	svc.post = function (url, data) {
		return $http.post(url, data);
	};

	return svc;
};

CommonService.$inject = ['$http', 'appSettings'];