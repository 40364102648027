export default function loginCallbackCtrl ($http, $location, appSettings, locationSvc) {
  
  // Used absUrl() to include querystring parameters that url() does not
  var url = $location.absUrl();

  //auth0 callback puts values in the query params instead of the fragment.
  if(window.location.search) {
    const baseUrl = url.split('?')[0];
    const newUrl = baseUrl + window.location.hash + window.location.search;

    window.location.assign(newUrl);
  }
  else {
    let callbackUrl = appSettings.apiBaseUrl + "login/callback";    
    var params = url.slice(url.indexOf('?'));
  
    $http.get(callbackUrl + params)
      .then(function (response) {
        if (!response.data.isExpiredState) {
          let signedInUrl = appSettings.viewSignedIn + (response.data.encodedReferrerUrl ? '?ref=' + response.data.encodedReferrerUrl : '');
          locationSvc.changeView(signedInUrl);
        } else {
          locationSvc.changeView(appSettings.viewLogin);
        }
      });
  }  
};

loginCallbackCtrl.$inject = ['$http', '$location', 'appSettings', 'locationSvc'];