async function loginCtrl($http, $location, appSettings, locationSvc) {
  let ref = $location.search().ref ? 'ref=' + encodeURIComponent($location.search().ref) : null;
  let qs = $location.search().qs ? 'qs=' + encodeURIComponent($location.search().qs) : null;
  let idp = $location.search().idp ? 'idp=' + $location.search().idp : null;
  let redirect_uri = 'redirect_uri=' + encodeURIComponent(window.location.origin + appSettings.baseUrl + '#' + appSettings.viewLoginCallback);

  var params = [redirect_uri, ref, qs, idp];

  var query = '?' + params.filter(function (param) { return param }).join('&');

  //if global feature toggling is enabled (driven by web.config values),
  //route to customLoginConfig 
  if (
    appSettings.hasOwnProperty('candidateFeatureFlagFeatureToggle') &&
    JSON.parse(appSettings.candidateFeatureFlagFeatureToggle)
  ) {
    return locationSvc.changeView(appSettings.customLoginConfig, query);
  } else {
    let loginConfigUrl = appSettings.apiBaseUrl + 'Login/Configuration';

    var url = loginConfigUrl + query;

    const response = await $http.get(url);
    
    window.location.replace(response.data.redirectUrl);
  }
}

export default loginCtrl;

loginCtrl.$inject = ['$http', '$location', 'appSettings', 'locationSvc'];
