var HeaderService = function ($http, appSettings, AuthService, CommonModalService, locationSvc) {
    var svc = {};

    svc.reset = function () {
        svc.headerEndpoint = null;
        svc.headerRequest = null;
        svc.headerData = {};
    };

    svc.setHeaderEndpoint = function (url) {
        //set the url into session storage in case of a hard refresh
        if (Modernizr.sessionstorage && url) sessionStorage.setItem(appSettings.headerEndpoint, encodeURI(url));
        svc.headerEndpoint = url;
        svc.headerRequest = null;
    };

    svc.getHeaderEndpoint = function () {
        //try to get it from the property
        var endpoint = svc.headerEndpoint;

        //fall back to session storage if not present (hard refreshes)
        endpoint = !endpoint && Modernizr.sessionstorage && sessionStorage.getItem(appSettings.headerEndpoint)
            ? decodeURI(sessionStorage.getItem(appSettings.headerEndpoint))
            : endpoint;

        return endpoint;
    }

    svc.getHeaderContent = async function () {
        //if there is already a current request, wait for it instead of creating a new one.
        if(svc.headerRequest) {
            return await svc.headerRequest;
        }
        else {
            await svc.refreshHeaderData();
            return svc.headerData;
        }
    }

    svc.getCandidateIdentifier = async function() {
        const headerContent = await svc.getHeaderContent();

        if(headerContent && headerContent.data) {
            return headerContent.data.candidateIdentifier;
        }
    }

    svc.refreshHeaderData = async function () {
        var currentEndpoint = svc.getHeaderEndpoint();

        if (currentEndpoint && AuthService.getAuthHeader()) {
            svc.headerRequest = $http.get(currentEndpoint);
            const response = await svc.headerRequest;
            svc.headerData = response;
        }
    };

    svc.getHeaderData = function () {
        return svc.headerData.data;
    };

    svc.hasHeaderEndpoint = function () {
        return angular.isString(svc.getHeaderEndpoint());
    };

    svc.hasHeaderData = function () {
        return ('data' in svc.headerData);
    };

    svc.shouldShowHeader = function () {
        var isAuthenticated = AuthService.getAuthHeader() ? true : false;
        var hasEndpoint = svc.hasHeaderEndpoint();
        var hasData = svc.hasHeaderData();
        var show = (isAuthenticated && hasEndpoint && hasData);

        return (show);
    };

    svc.logout = function () {
        function modalConfirm() {
            AuthService.clearSession();
            CommonModalService.close();
            locationSvc.changeView(appSettings.viewLogout);
        }

        CommonModalService.open(svc.getHeaderData().logoutConfirm, 'confirm', modalConfirm);

    }

    svc.reset();

    return svc;
};

HeaderService.$inject = ['$http', 'appSettings', 'AuthService', 'CommonModalService', 'locationSvc'];

if (typeof app !== 'undefined') {
    app.factory('HeaderService', HeaderService);
}
else {
    module.exports = HeaderService;
}
