import CommonModule from 'modules/common.module';
import HeaderService from 'header/header.service';
import { authServiceEventBus, AuthServiceEvents } from 'common/auth.service';
import ClickHeader from 'common/components/header/click-header.vue';

export let headerService;
export default angular.module('header-module', [ CommonModule ])
	.factory('HeaderService', HeaderService)
	.value("click-header", ClickHeader)
	.run(['HeaderService', '$injector', function(HeaderService, $injector) {
		headerService = $injector.get('HeaderService');
		authServiceEventBus.on(AuthServiceEvents.SessionCleared, () => { HeaderService.reset(); });

		authServiceEventBus.on(AuthServiceEvents.AuthenticatedUserChanged, async () =>  {
			await HeaderService.refreshHeaderData();
		});
	}])
	.name;


