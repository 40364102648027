export default function submitButton () {
	return {
		require: ['^form'],
		scope: {
			submitButton: '&',
			isValid: '='

		},
		link: function (scope, element, attrs, ctrls) {
			element.bind('keypress', function(event){
				event.charCode == 13 ? scope.submitButton() : '';
			});

			element.bind('click', function () {
				var formValid = ctrls[0].$valid;
				if (!formValid) return;
				if (angular.isDefined(scope.submitButton)) element.prop('disabled', true);

				scope.submitButton().finally(function () {
					element.prop('disabled', false);
				});
			});
		}
	};
};

submitButton.$inject = [];
