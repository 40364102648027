import orderBy from "lodash.orderby";

export default function CandidateProcessService(
  HeaderService,
  appSettings,
  $http
) {
  return {
    filterGetAllResponseByTags: function(getAllResponse, processTags) {
      var filteredGetAllResponse = JSON.parse(JSON.stringify(getAllResponse));

      var hasMatchingTag = function(process) {
        return processTags.some(processTag =>
          process.tags.includes(processTag)
        );
      };

      var filterProcessesByTags = function(processes) {
        return processes.filter(process => hasMatchingTag(process));
      };

      var filterProcessGroup = function(processGroup) {
        if (processGroup) {
          var filteredProcesses = filterProcessesByTags(processGroup.processes);
          if (filteredProcesses.length) {
            processGroup.processes = filteredProcesses;
          } else {
            processGroup = null;
          }
        }
        return processGroup;
      };

      if (filteredGetAllResponse.activeProcess) {
        filteredGetAllResponse.activeProcess = hasMatchingTag(
          filteredGetAllResponse.activeProcess
        )
          ? filteredGetAllResponse.activeProcess
          : null;
      }
      filteredGetAllResponse.completed = filterProcessGroup(
        filteredGetAllResponse.completed
      );
      filteredGetAllResponse.declined = filterProcessGroup(
        filteredGetAllResponse.declined
      );
      filteredGetAllResponse.pending = filterProcessGroup(
        filteredGetAllResponse.pending
      );
      filteredGetAllResponse.stopped = filterProcessGroup(
        filteredGetAllResponse.stopped
      );

      return filteredGetAllResponse;
    },
    flattenGetAllResponse(getAllResponse) {
      var processes = [];
      if (getAllResponse) {
        if (getAllResponse.activeProcess) {
          processes.push(getAllResponse.activeProcess);
        }
        if (getAllResponse.declined) {
          processes.push(...getAllResponse.declined.processes);
        }
        if (getAllResponse.stopped) {
          processes.push(...getAllResponse.stopped.processes);
        }
        if (getAllResponse.pending) {
          processes.push(...getAllResponse.pending.processes);
        }
        if (getAllResponse.completed) {
          processes.push(...getAllResponse.completed.processes);
        }
      }
      return processes;
    },
    getCurrentProcess(processes) {
      var sortedProcesses = this.sortProcesses(processes);

      // Sort incomplete processes before completed ones
      sortedProcesses = orderBy(
        sortedProcesses,
        [_ => _.statusEnum === "Completed"],
        ["asc"]
      );

      return sortedProcesses && sortedProcesses.length && sortedProcesses[0];
    },
    sortProcesses(processes) {
      if (!processes || !processes.length) return processes;

      var sortedProcesses = JSON.parse(JSON.stringify(processes));

      const minDate = new Date(-8640000000000000).toISOString();

      var selectCompletedDateIfCompleted = process =>
        (process.statusEnum === "Completed" ? process.completedDate : null) ||
        minDate;
      var selectDueDateIfIncomplete = process =>
        (process.statusEnum !== "Completed" ? process.dueDate : null) ||
        minDate;

      sortedProcesses = orderBy(
        sortedProcesses,
        [selectDueDateIfIncomplete, selectCompletedDateIfCompleted, "id"],
        ["asc", "desc", "asc"]
      );

      return sortedProcesses;
    },
    getAll: async function(processTags) {
      var candidateIdentifier = await HeaderService.getCandidateIdentifier();
      var url =
        appSettings.apiBaseUrl +
        "Candidates/" +
        candidateIdentifier +
        "/Processes";

      var response = await $http.get(url);
      var processes = response.data.processes;
      if (processTags && processTags.length) {
        var hasMatchingTag = function(process) {
          return processTags.some(processTag =>
            process.tags.includes(processTag)
          );
        };

        processes = processes.filter(process => hasMatchingTag(process));
      }
      return processes;
    }
  };
}

CandidateProcessService.$inject = ["HeaderService", "appSettings", "$http"];
