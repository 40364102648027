import Vue from "vue";
import Vuex from "vuex";
import "common/polyfills/object.assign.polyfill";
import storeModules from 'store/modules';

Vue.use(Vuex);

/*
  The store's primary purpose is to own application/module in-memory state 
  and any mutations to that state. A general rule of thumb I have seen is 
  that if a piece of state needs to be accessed by more than two components 
  or two components that are not a direct parent/child of each other, put the
  state in the store. 
  
  For state that needs to be accessed across many components, but within a 
  segment of the application (i.e. Action Center, Data Fields, etc), consider 
  a separate store module for that state. 
  
  Typically, if someone wants to use store state in their rendered output, 
  they'd directly bind to it in their component via computeds and reference
  those in their template. One can also use watchers to watch a piece of state 
  and do things in a component when that watcher fires. 

  Some additional side-effects that might take place in an action 
  include:
    * (more common) Persisting some of the state to storage (localStorage, sessionStorage, 
      remote storage via api abstractions).  
    * (less common) Emitting generic events from an action can be useful in the scenarios that you 
      need to perform a side-effect outside of Vue when a store action occurs (i.e. 
      asynchronous app logging)

  ************ ADDING MODULES ************
    * Modules should be added into the store/modules directory and in addition, imported on the store/modules.js file
    * Modules should implement reset action so that the main store can dispatch a reset to all modules when the main store is reset
*/

const modules = storeModules();

Object.values(modules).forEach(module => {
  module.namespaced = true;
});

const getDefaultState = () => {
  return {
  };
};

// state
const state = getDefaultState();

// getters
export const getters = {
};

// actions
export const actions = {
  async reset({ commit, dispatch }) {
    // reset modules first
    for (var moduleName in modules) {
      // all modules should implement a reset action
      await dispatch(`${moduleName}/reset`);
    }

    // then reset main store
    commit("resetState");
  },
  
  async logout({ dispatch }) {
    window.sessionStorage.clear();
    await dispatch("reset");
  }
};

// mutations
export const mutations = {
  resetState(state) {
    Object.assign(state, getDefaultState());
  }
};

const storeInstance = new Vuex.Store({
  state,
  getters,
  actions,
  mutations,
  modules,
  // Disable in production mode for performance per: https://vuex.vuejs.org/guide/strict.html#development-vs-production
  strict: process.env.NODE_ENV !== "production"
});


// export store
export default storeInstance;