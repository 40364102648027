const getDefaultState = () => {
    return {
        template: null,
        message: '',
        visible: false
    }
}

// State
const state = getDefaultState();

// Getters
export const getters = {
    template: state => state.template,
    message: state => state.message,
    visible: state => state.visible
}

// Actions
export const actions = {
    showMessage: {
        root: false,
        handler({commit}, payload) {
            commit('ui-messages/setMessage', payload, {root: true})
            commit('ui-messages/setVisibility', true, {root: true})
        }
    },
    hideMessage({commit}) {
        commit('ui-messages/setMessage', {}, {root: true})
        commit('ui-messages/setVisibility', false, {root: true})
    },
    resetState({commit, state}) {
        commit('ui-messages/reset', state);
    }
}

// Mutations
export const mutations = {
    setMessage (state, payload) {
        state.message = payload.message
        state.template = payload.template
    },
    setVisibility (state, value) {
        state.visible = value
    },
    resetState(state) {
        Object.assign(state, getDefaultState());
    }
}

export default {
    state,
    getters,
    actions,
    mutations
};
