import 'general/progressError'; //provides the 'angular-loading-bar' module
import 'common/vue.setup'; //provides vue and registers global libraries
import "oclazyload"; // provides 'oc.lazyLoad'

// the app specific variables file must be imported first
// so that our theme will use the variables when compiling
import 'bootstrap/variables.scss';
import '@clickboarding/style/theme.scss';

import '@clickboarding/vue-components/dist/vue-components.css';
import '@clickboarding/savage/dist/savage.css';

//These stylesheets need to be up here to maintain the order they were included when they were brought in via bundles (cascading)
import '../CSS/structure.scss';
import '../CSS/skin.scss';
import '../CSS/utility.scss';
import 'common/css/icons.css';
import '../CSS/components.scss';
import 'common/css/roseform.scss';

import { IconProvider } from 'common/icon.module';
import CommonModule from 'modules/common.module';
import AuthModule from 'auth.module';
import AnalyticsModule from "modules/app-insights-analytics.module";
import ErrorModule from 'modules/error.module';
import AppSettingsModule from 'common/app-settings.module';
import HeaderModule from 'header/header.module';
import VueNgRoutingModule from 'common/modules/vue-ng-routing.module';
import AppRoutesModule from 'modules/appRoutes.module';
import UiMessagesModule from 'modules/uiMessages.module';
import ProcessModule from 'modules/process.module';
import LoginModule from 'modules/login.module';
import UiComponentsModule from 'modules/uiComponents.module';
import HtmlViewModule from 'modules/htmlview.module'; //is this used?
import FormsModule from 'modules/forms.module';
import OverviewModule from 'modules/overview.module';
import UnsupportedBrowserModule from 'modules/unsupportedBrowser.module'; // provides uaParser for User-Agent Parsing
import { httpEventBus, HttpEvents } from 'common/http-event-bus';
import analyticsService from 'common/services/analytics.service';
import { USER_SIGNED_OUT } from 'analytics/event-types';
import ClickFooter from "components/footer/click-footer";

import cbAppController from 'cbAppCtrl';

import '../CSS/Site.scss';
import 'common/css/components.scss';

import store from './store';
import ngVueModuleFactory from 'common/ngVue.moduleFactory';

const ngVueModule = ngVueModuleFactory({ additionalRootVueInstanceProps: { store }});

import bootstrapSetup from 'common/bootstrap/setup';
bootstrapSetup();

export default angular.module('cbCandidateApp', [
	// ErrorModule must be configured before CommonModule
	// so that the auth interceptor can catch 401/403
	// before they reach the error interceptor on the
	// way out.
	ErrorModule,
	CommonModule,
	AuthModule,
	AppSettingsModule,
	HeaderModule,
	AnalyticsModule,
	AppRoutesModule,
	UiMessagesModule,
	ProcessModule,
	LoginModule,
	UiComponentsModule,
	HtmlViewModule,
	FormsModule,
	OverviewModule,
	UnsupportedBrowserModule,
	VueNgRoutingModule,
	"oc.lazyLoad",
	'angular-loading-bar',
	'ng.deviceDetector',
	ngVueModule
])
	.value('click-footer', ClickFooter)
	.controller('cbAppCtrl', cbAppController)


.config(['$sceDelegateProvider', '$locationProvider', '$compileProvider','cfpLoadingBarProvider',
	function ($sceDelegateProvider, $locationProvider, $compileProvider, cfpLoadingBarProvider) {
		$locationProvider.html5Mode({ enabled: false });
		$locationProvider.hashPrefix("");
		$compileProvider.debugInfoEnabled(false);

	}
])
.run(['$rootScope', '$location', 'appSettings', 'locationSvc', 'uaParser', 'uiMessages',
	function ($rootScope, $location, appSettings, locationSvc, uaParser, uiMessages) {

		var redirectToUnsupported = function() {
			locationSvc.changeView(appSettings.viewUnsupported);
		}

		/*
			Check for unsupported browsers
			We only do this on first load / run; so there are mechanisms to get around it
		*/
		var browser = uaParser.getBrowser();
		switch (browser.name)
		{
			case 'IE': // IE < 11 is unsupported
			case 'IEMobile':
				if (browser.major < 11) redirectToUnsupported();
				break;
			case 'Safari': // Safari < 9 is unsupported
			case 'Mobile Safari':
				if (browser.major < 9) redirectToUnsupported();
				break;
		}

		$rootScope.$on("$locationChangeSuccess", function (event) {
			uiMessages.hideMessage();
			appSettings.viewPath = $location.url();
		});

		IconProvider.loadIconSprite();

		httpEventBus.on(HttpEvents.AuthError, (status) => {
			analyticsService.track(USER_SIGNED_OUT, {});
			analyticsService.reset();
			locationSvc.changeView(appSettings.viewLogin);
		});
	}
])
.name;
