import { i18n } from 'common/lang-config';

export default function ContentService($ngVue, ExperienceService) {
  return {
    setLocale: function(locale) {
      const props = $ngVue.getRootProps();
      props['i18n'].locale = locale;
    },
    getString: function(path, params) {
      let localContent;
      let stringPath = path;
  
      if(ExperienceService.isExperienceString(path)) {
        let currentExperience = ExperienceService.getCurrentExperience();
        stringPath = ExperienceService.getExperienceStringPath(currentExperience, path);
      }
  
      return i18n.t(stringPath, params);
    }
  }
}

ContentService.$inject = ['$ngVue', 'ExperienceService'];