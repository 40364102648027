import appSettings from '../../appSettings';

const getDefaultState = () => {
  return {
    featureFlags: null,
    redirectUrls: {},
  };
};

// state
const state = getDefaultState();

// getters
export const getters = {
  getFeatureFlags() {
    return state.featureFlags;
  },
  hasFeature: (state) => (featureName) => {
    return state.featureFlags[featureName] === true;
  },
};

// actions
export const actions = {
  reset({ commit }) {
    commit('resetState');
  },
};

// mutations
export const mutations = {
  setFeatureFlags(state, featureFlags) {
    state.featureFlags = featureFlags;
  },
  resetState(state) {
    Object.assign(state, getDefaultState());
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
