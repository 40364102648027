import appSettingsModule from 'common/app-settings.module';
import CommonModule from 'modules/common.module';
import ProcessService from 'process/process.service';
import processCtrl from 'process/processCtrl';
import processView from 'process/process-view';

export default angular.module('candidate-process-module', [ appSettingsModule, CommonModule ])
	.factory('ProcessService', ProcessService)
	.controller('processCtrl', processCtrl)
	.value('process-view', processView)
	.name;

