import { AxiosLocal } from 'common/services/axiosLocal.service';
import Vue from 'common/vue.setup';
import CommonModule from 'modules/common.module';
import PrimaryMessageBus from 'common/components/cb-primary-message-bus';
import { httpEventBus, HttpEvents } from 'common/http-event-bus';
import { VueResourceStrategy, UploadXhrStrategy, AxiosStrategy } from 'common/http-strategy';

export let $ngVue;
export default angular.module('auth-module', [ CommonModule ])
  .run(['$timeout', '$rootScope', 'AuthService', 'cfpLoadingBar','$injector', function($timeout, $rootScope, AuthService, cfpLoadingBar, $injector) {
      /* Setup PreRequest strategies */
      UploadXhrStrategy.setPreRequestHandler(function preRequestHandler (request, loader) {
          loader = $timeout(function () {
              cfpLoadingBar.start();
          }, 1250);

          request.setRequestHeader('Authorization', AuthService.getAuthHeader());

          return loader;
      });

      VueResourceStrategy.setPreRequestHandler(function preRequestHandler (request, loader) {
          loader = $timeout(function () {
              cfpLoadingBar.start();
          }, 1250);

          request.headers.set('Authorization', AuthService.getAuthHeader());

          return loader;
      });

      AxiosStrategy.setPreRequestHandler(function preRequestHandler (config) {
        config.cbLoader = $timeout(function () {
            cfpLoadingBar.start();
        }, 1250);
  
        config.headers.Authorization = AuthService.getAuthHeader();
  
        return config;
      });

      /* Setup RequestError strategies */
      UploadXhrStrategy.setRequestErrorHandler(function(xhr) {
          const isSoftError = xhr.status > 399
              && xhr.status < 500
              && xhr.status !== 401
              && xhr.status !== 403;

          if(isSoftError) {
              let response = JSON.parse(xhr.response);
              PrimaryMessageBus.$emit('send-error-message', response[0].message);
              return Promise.reject(xhr);
          } else if (xhr.status === 401 || xhr.status === 403) {
              AuthService.clearSession();
              httpEventBus.emit(HttpEvents.AuthError, xhr.status);
              return Promise.reject(response);
          } else if (xhr.status >= 400) {
              $rootScope.$broadcast('cfpLoadingBar:error', JSON.parse(xhr.response));
          }
      });

      /* Setup RequestSuccess strategies */
      UploadXhrStrategy.setRequestSuccessHandler(function(xhr) {
          var hdr = xhr.getResponseHeader('Authorization');
          if (hdr) AuthService.setAuthHeader(hdr);
      });


      /* Setup RequestComplete strategies */
      UploadXhrStrategy.setRequestCompleteHandler(function(response, loader) {
          //our upload component may pass a null response if there is an
          //error in a pre-flight check, so any use of response should be
          //placed in a null check condition
          cfpLoadingBar.complete();
          $timeout.cancel(loader);
      });

      VueResourceStrategy.setRequestCompleteHandler(function(response, loader) {
          cfpLoadingBar.complete();
          $timeout.cancel(loader);

          const isSoftError = response.status > 399
              && response.status < 500
              && response.status !== 401
              && response.status !== 403;

          if(isSoftError) {
              PrimaryMessageBus.$emit('send-error-message', response.body[0].message);
              return Promise.reject(response);
          } else if (response.status === 401 || response.status === 403) {
              AuthService.clearSession();
              httpEventBus.emit(HttpEvents.AuthError, response.status);
              return Promise.reject(response);
          } else if (response.status >= 400) {
              $rootScope.$broadcast('cfpLoadingBar:error', response.body);
          } else {
              var hdr = response.headers.get('Authorization');
              if (hdr) AuthService.setAuthHeader(hdr);
          }
      });

      // VUE INTERCEPTOR SETUP
      Vue.http.interceptors.push((request, next)  => {
          if (request.url) {
            const loader = VueResourceStrategy.handlePreRequest(request, loader);
            next((response) => {
              VueResourceStrategy.handleRequestComplete(response, loader);
            });
          }
      });

      AxiosStrategy.setRequestSuccessHandler(function(response) {
        cfpLoadingBar.complete();
        if (response && response.config) $timeout.cancel(response.config.cbLoader);
  
        var hdr = response && response.headers && response.headers.authorization;
        if (hdr) AuthService.setAuthHeader(hdr);
  
        return response;
      });
  
      AxiosStrategy.setRequestErrorHandler(function(error) {
        cfpLoadingBar.complete();
        if (error && error.config) $timeout.cancel(error.config.cbLoader);
  
        const response = error.response || {}; // default the object if no response received
  
        const isSoftError = response.status > 399
            && response.status < 500
            && response.status !== 401
            && response.status !== 403;
  
        if (isSoftError) {
          PrimaryMessageBus.$emit('send-error-message', response.data[0].message);
          return Promise.reject(response);
        } else if (response.status === 401 || response.status === 403) {
          AuthService.clearSession();
          httpEventBus.emit(HttpEvents.AuthError, response.status);
          return Promise.reject(response);
        } else {
          $rootScope.$broadcast('cfpLoadingBar:error', response.data);
        }
      });
  
  
      AxiosLocal.interceptors.request.use(function (config) {
        // Do something before request is sent
        return AxiosStrategy.handlePreRequest(config);
      }, function (error) {
        return AxiosStrategy.handleRequestError(error);
      });
  
      AxiosLocal.interceptors.response.use(function (response) {
        return AxiosStrategy.handleRequestSuccess(response);
      }, function (error) {
        return AxiosStrategy.handleRequestError(error);
      });

      $ngVue = $injector.get('$ngVue');
  }])
  .name;
