export default function radioGroup ($http, $location, uiMessages) {
	var linker = function (scope, element, attrs) {

		scope.action = function() {
			if(scope.model === scope.disagree.value){
				uiMessages.showMessage(scope.messageType, scope.message);
			} else {
				uiMessages.hideMessage();
			}
		}

		scope.$watch('agree', function(newval,oldval){
			if(newval === oldval) return;

			if (scope.model === scope.disagree.value) {
				uiMessages.showMessage(scope.messageType, scope.message);
			}
		})
	};

	return {
		restrict: "E",
		replace: true,
		link: linker,
		template: require('general/radioGroup.html'),
		scope: {
			model: '=',
			agree: '=',
			disagree: '=',
			message: '=',
			messageType: '='
		}
	};
};

radioGroup.$inject = ['$http', '$location', 'uiMessages'];
