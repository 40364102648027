import 'signature_pad-1.3.6/signature_pad';
import 'angular-sanitize'; //provides 'ngSanitize' module

import AppSettingsModule from 'common/app-settings.module';
import UiMessagesModule from 'modules/uiMessages.module';

import formsCtrl from 'forms/formsCtrl';
import formSplashCtrl from 'forms/formSplashCtrl';

import FormService from 'services/form.service';
import RoseForms from 'common/services/roseForm.service';
import roseForm from 'forms/roseForm';
import formHeader from 'forms/formHeader';
import formI9 from 'forms/formI9';
import CbViewTitle from 'common/components/cb-view-title.vue';
import FormDocumentUploadComponent from 'common/forms/form-document-upload.vue';
import SavageFormLoaderComponent from 'common/savage/savage-form-loader.vue';
import SignedDocumentComponent from 'forms/signed-document.vue';
import FormReview from 'common/forms/form-review.vue';
import ExternalForm from 'forms/external-form.vue';
import FormI9 from 'forms/form-i9.vue';
import SignatureCreate from 'forms/signature-create.vue';

export default angular.module('candidate-forms-module', [AppSettingsModule, UiMessagesModule, 'ngSanitize'])
	.factory('FormService', FormService)
	.factory('RoseForms', RoseForms)
	.controller('formsCtrl', formsCtrl)
	.controller('formSplashCtrl', formSplashCtrl)
	.directive('formHeader', formHeader)
	.directive('roseForm', roseForm)
	.directive('formI9', formI9)
	.value('cb-view-title', CbViewTitle)
	.value('form-document-upload', FormDocumentUploadComponent)
	.value('savage-form-loader', SavageFormLoaderComponent)
	.value('signed-document', SignedDocumentComponent)
	.value('form-review', FormReview)
	.value('external-form', ExternalForm)
	.value('form-i9', FormI9)
	.value('signature-create', SignatureCreate)
	.name;
