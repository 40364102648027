export default function PasswordService ($q) {
	var user = {};
	return {
		validations: {
			capital: false,
			lower: false,
			special: false,
			number: false,
			minlength: false,
			maxlength: false,
			valid: false
		},
		isValid: function() {
			for (var key in this.validations) {
				if (!this.validations[key]) {
					return false;
				}
			}

			return true;
		},
		setInvalid: function() {
			for(var key in this.validations) {
				this.validations[key] = false;
			}
		},
		compare: function(password1, password2, exact, cb) {
			var valid = true;

			exact = exact || false;

			var password1Value = angular.isString(password1) ? password1 : '';
			var password2Value = angular.isString(password2) ? password2 : '';

			//check if exact match
			var exactMatch = password2Value === password1Value;

			//exact match supercedes all, otherwise if an exact compare, must be exact match, else check for partial match
			var match = exactMatch || (password2Value === (exact
				? password1Value
				: password1Value.substr(0, password2Value.length)));

			if(cb){
				return cb(match, exactMatch);
			} else {
				return match;
			}
		},
		validate: function(value){
			var valid = true;

			if(value) {
				// validate our strength cases
				if (/^(?=.*[A-Z]).+$/.test(value)) {
					this.validations.capital = true;
				} else {
					this.validations.capital = false;
				}
				if (/^(?=.*[a-z]).+$/.test(value)) {
					this.validations.lower = true;
				} else {
					this.validations.lower = false;
				}
				if (/[@~`!#$%\^&*+=\-\[\]\\';,/{}|\\":<>\?]/g.test(value)) {
					this.validations.special = true;
				} else {
					this.validations.special = false;
				}
				if (/^(?=.*[0-9]).+$/.test(value)) {
					this.validations.number = true;
				} else {
					this.validations.number = false;
				}
				if (value.length >= 8) {
					this.validations.minlength = true;
				} else {
					this.validations.minlength = false;
				}
				if (value.length <= 256) {
					this.validations.maxlength = true;
				} else {
					this.validations.maxlength = false;
				}

				for (var key in this.validations) {
					if(key == 'valid') continue;
					if (!this.validations[key]) {
						this.validations.valid = false;
						valid = false;
						break;
					}
				}

				if (valid) this.validations.valid = true;
			}
		}
	};
};

PasswordService.$inject = ['$q'];
