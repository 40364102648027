import debounce from 'lodash.debounce';

export default function formI9 ($rootScope, $http, $location, $window, $timeout, uiMessages, cfpLoadingBar) {
  var linker = function (scope, element, attrs) {
    scope.model.frameLoaded = false;
    scope.model.isLastPage = false;
    scope.frameClass = "lg-step-1"; 
    
    var originalGoBack = angular.copy(scope.goBack);
    
    var formMetaData = {
      isValid: true,
      currentStep: 1,
      totalSteps: 4, //default if they haven't added additional preparers
      isFirstStep: true,
      isLastStep: false
    };

    function updateFormMetaData(newData) {
      for(var metaDataKey in formMetaData) {
        if(metaDataKey in newData) {
          formMetaData[metaDataKey] = newData[metaDataKey];
        }
      }
    }

    function isLastPage() {
      return formMetaData.isLastStep;
    }

    function isFirstPage() {
      return formMetaData.isFirstStep;
    }

    function backButtonDisabled() {
      return isFirstPage() || isLastPage();
    }

    function getNextText() {
      return isLastPage() ? "Submit" : "Next";
    }

    scope.$watch('currentPage', function () {
      if (typeof scope.currentPage !== "undefined") {
          console.log("formI9.js $watch currentPage");
          $http.get(scope.currentPage.contentUrl)
          .then(function (data) {
            scope.nextText = "Next";
            scope.content = data.data;
            cfpLoadingBar.start();
            setTimeout(loadiFrameData, 500);//race condition, check if loaded yet
        });
      }
    });

    function loadiFrameData() {
        if (scope.model.frameLoaded) {
            console.log("formI9.js loadiFrameData postMessage sendData");
        document.querySelector('#i9Frame').contentWindow.postMessage(JSON.stringify({ event:'sendData', content:scope.content }), $location.protocol() + '://' + $location.host());
        scope.model.pageRendered = true;
      }
      else {
        setTimeout(loadiFrameData, 500);
      }
    }

    scope.goBack = function (e) {
      document.querySelector('#i9Frame').contentWindow.postMessage(JSON.stringify({ event:'onBack' }), scope.content.baseUrl);
    }

    var i9FrameLoaded = function () {
        scope.model.frameLoaded = true;
        console.log("formI9.js i9FrameLoaded");
    };

    document.querySelector('#i9Frame').addEventListener('load', i9FrameLoaded);

    var i9MessageHandler = function (e) {
        console.log("formI9.js i9MessageHandler start");
      uiMessages.hideMessage();
      scope.model.isLastPage = false;
      var message = JSON.parse(e.data);
      updateFormMetaData(message);
      scope.backDisabled = backButtonDisabled();
      scope.nextText = getNextText();
        scope.model.isLastPage = isLastPage();

        console.log("formI9.js i9MessageHandler event: " + message.event);

      switch (message.event) {
        case 'onFormLoadResult':
          cfpLoadingBar.complete();
          break;
        case 'onEditStart':
          // does not appear to ever fire
          break;
        case 'onBackStart':
        case 'onNextStart':
        case 'onSubmitStart':
        case 'onUserInfoResult':
          break;
        case 'onEditResult':
          // edit takes you back to the first page
          $window.scrollTo(0, 0);
          break;
        case 'onBackResult':
          $window.scrollTo(0, 0);
          break;
        case 'onNextResult':
          if (!formMetaData.isValid) {
            uiMessages.showMessage('error','There are one or more problems with the data entered, you will need to fix the data marked with a red flag in order to continue.');
          }
          $window.scrollTo(0, 0);
          break;
        case 'onSubmitResult':
              console.log("formI9.js i9MessageHandler onSubmitResult");
              if (!formMetaData.isValid) {
            uiMessages.showMessage('info','You will need to agree to electronically sign the document in order to continue.');
            $window.scrollTo(0, 0);
          } else {
            $http.post(scope.currentPage.eventUrl).then(function (response) {
                console.log("formI9.js i9MessageHandler onSubmitResult post to scope.currentPage.eventUrl nextSuccess");
                scope.model.nextSuccess(response.data);
            });
          }
          break;
      }

      scope.$apply();      

      $timeout(function(){
        setFrameClass(true);
      }, 50);
    };

    function setFrameClass(force){
      var newWidth = $window.document.documentElement.clientWidth;
      if(force || currentWidth !== newWidth) {
        var iframeContainerElement = document.querySelector('div.formI9');
        var containerWidth = iframeContainerElement.clientWidth;
        
        //determine bootstrap breakpoint based on container width
        var bootstrapPrefix = containerWidth >= 1200 ? "lg"
            : containerWidth >= 992 ? "md"
            : containerWidth >= 768 ? "sm"
            : "xs";
        
        //also assign class with bootstrap breakpoint and step number to the iframe (i.e xs-step-1, md-step-1, etc)
        scope.frameClass = bootstrapPrefix + "-step-" + formMetaData.currentStep;
        scope.$apply();  
        currentWidth = newWidth;
      }
    }

    //track width so we don't have to execute this code if only a vertical resize takes place
    var currentWidth = $window.document.documentElement.clientWidth;
    var windowResizeEventHandler = debounce(setFrameClass, 100);
    $window.addEventListener("resize", windowResizeEventHandler);

    $window.addEventListener("message", i9MessageHandler);

    var removeNextClickListener = scope.$on('cbFormCtrl:nextClick', function(e) {        
        var eventName = isLastPage() ? 'onSubmit' : 'onNext';
        console.log("formI9.js cbFormCtrl:nextClick eventName: " + eventName);
      document.querySelector('#i9Frame').contentWindow.postMessage(JSON.stringify({ event: eventName }), scope.content.baseUrl);
    });

    scope.$on('$destroy', function () {
      // revert override of goBack so that other 
      // directives function normally
      scope.goBack = originalGoBack;
      removeNextClickListener();
      $window.removeEventListener("message", i9MessageHandler);
      $window.removeEventListener("resize", windowResizeEventHandler);
      document.querySelector('#i9Frame').removeEventListener('load', i9FrameLoaded);
    });
  };

  return {
    restrict: "E",
    replace: true,
    link: linker,
    template: require('forms/formI9.html'),
    scope: {
      currentPage: '=',
      model: '=',
      nextText: '=',
      backDisabled: '=',
      goBack: '='
    }
  };
};

formI9.$inject = ['$rootScope', '$http', '$location', '$window', '$timeout', 'uiMessages', 'cfpLoadingBar'];