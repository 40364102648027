export const ExperienceTypes = Object.freeze({
  Onboarding: 'onboarding',
  Offboarding: 'offboarding',
  EmployeeJourney: 'employeeJourney'
});

export default function CandidateExperienceService(appSettings) {
  return {
    getCurrentExperience: function() {
      let currentExperience = ExperienceTypes.Onboarding;
      let href = window.location.hostname.toLowerCase();
  
      if (href.indexOf("myonboarding.com") > -1) currentExperience = ExperienceTypes.Onboarding;
      if (href.indexOf("myoffboarding.com") > -1) currentExperience = ExperienceTypes.Offboarding;
      if (href.indexOf("myemployeejourney.com") > -1) currentExperience = ExperienceTypes.EmployeeJourney;
      
      return currentExperience;
    },
    isExperienceString: function(path) {
      return this.experienceContent.strings.includes(path);
    },
    getExperienceStringPath: function(experience, path) {
      return experience + '.' + path;
    },
    getCurrentExperienceProcessTags: function() {
      let currentExperience = this.getCurrentExperience();
      return this.experienceProcessTags[currentExperience];
    },
    experienceContent: {
      strings: [
        "headerTitle.message",
        "profile.welcome",
        "profile.siteTerms.message"
      ]
    },
    experienceProcessTags: {
      [ExperienceTypes.Onboarding]: [
        "Onboarding"
      ],
      [ExperienceTypes.Offboarding]: [
        "Offboarding"
      ],
      [ExperienceTypes.EmployeeJourney]: [
        "Employee Lifecycle Events"
      ]
    }
  }
}

CandidateExperienceService.$inject = ['appSettings'];