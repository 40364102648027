<template>
  <form
    ref="form"
    @submit.prevent="handleLogin()"
    name="loginForm"
    class="needs-validation cb-login-form bg-white padding-lrg margin-c"
    novalidate
    :class="validated"
  >
    <div class="header text-c">
      <span class="logo-name">{{ headerTitle }}</span>
    </div>
    <ui-messages></ui-messages>
    <div v-if="showCandidateForm">
      <div class="input-contain" v-show="!mfaRequired && !mobilePhoneRequired">
        <label for="userName">Username</label>
        <input
          id="username"
          type="text"
          placeholder="Enter your username..."
          autofocus
          tabindex="1"
          autocomplete="off"
          name="Username"
          v-model="username"
          required
          class="form-control"
        />
        <div class="invalid-feedback cb-error-input">
          Please enter your Username.
        </div>
      </div>
      <div class="input-contain margin-med-T" v-show="!mfaRequired && !mobilePhoneRequired">
        <label for="password">Password</label>
        <input
          class="form-control"
          id="password"
          type="password"
          placeholder="Enter your password..."
          tabindex="2"
          name="Password"
          v-model="password"
          required
        />
        <div class="invalid-feedback cb-error-input">
          Please enter your Password.
        </div>
      </div>
      <div class="text-c padding-med margin-med-B" v-show="!mfaRequired && !mobilePhoneRequired">
        <a href="" @click.prevent="handleForgot">Forgot your password?</a>
      </div>
      <div class="input-contain margin-med-B" v-show="mfaRequired && !mobilePhoneRequired">
        <label for="mfaCode">MFA Code</label>
        <input
          class="form-control"
          id="mfaCode"
          type="text"
          placeholder="Enter your MFA code..."
          tabindex="3"
          name="MFA Code"
          v-model="mfaCode"
          required
        />
      </div>
      <div class="input-contain margin-med-B" v-show="!mfaRequired && mobilePhoneRequired">
        <label for="mobilePhoneNumber">Mobile Phone Number</label>
        <input
          class="form-control"
          id="mobilePhoneNumber"
          type="text"
          tabindex="4"
          name="Mobile Phone Number"
          v-model="mobilePhoneNumber"
          required
        />
        <label for="phoneNumberOptIn">Opt In to SMS/Text Messaging</label>
        <input type="checkbox" v-model="phoneNumberOptIn" name="phoneNumberOptIn" id="phoneNumberOptIn" required />
        <span> By providing my mobile number above, I agree to receive SMS/text messages for the purpose of facilitating Multi-Factor Authentication (MFA).</span>
      </div>
      <button type="submit" class="cb-btn primary">Sign In</button>
    </div>

    <button
      id="auth0-sso-btn"
      v-if="showAuth0SignInButton"
      @click.prevent="handleAuth0Redirect"
      class="cb-btn primary margin-sm-T"
    >
      Sign In with Auth0
    </button>
    <div id="idp-configs" v-if="showIdPLoginButton">
      <div v-for="(config, index) in getIdPConfigurations" :key="index">
        <button @click.prevent="handleSpInitiatedSSO(config)" class="cb-btn primary margin-sm-T">
          Sign In with {{ config.idPName }}
        </button>
      </div>
    </div>
  </form>
</template>

<script>
import { authService } from 'modules/common.module.js';
import { mapGetters, mapActions } from 'vuex';
import UiMessages from '../components/ui-messages/ui-messages.vue';
import appSettings from '../appSettings';
export default {
  name: 'custom-login-form',
  components: {
    UiMessages,
  },
  props: {
    headerTitle: String,
    showAuth0SignInButton: Boolean,
    showIdPLoginButton: Boolean,
    showCandidateForm: Boolean,
  },
  data() {
    return {
      username: '',
      password: '',
      mfaCode: null,
      mobilePhoneNumber: '',
      phoneNumberOptIn: false,
      state: '',
      host: '',
      validated: '',
      mfaRequired: false,
      mobilePhoneRequired: false
    };
  },
  computed: {
    ...mapGetters('customLogin', ['getLoginRedirectUri', 'getRedirectUrl', 'getIdPConfigurations']),
  },
  methods: {
    ...mapActions('ui-messages', ['showMessage', 'hideMessage']),
    ...mapActions('auth', ['setAuthenticatedUserId']),
    async handleLogin(e) {
      this.validated = 'was-validated';
      if (!this.mobilePhoneRequired && !this.mfaRequired) this.hideMessage();
      if (!this.username || !this.password) return;
      if (this.mfaRequired && !this.mfaCode) return;
      if (this.mobilePhoneRequired && (!this.mobilePhoneNumber || !this.phoneNumberOptIn)) return;
      authService.clearSession();
      const stateQueryParameter = 6;
      var credentials = {
        username: this.username.trim(),
        password: this.password,
        mfaCode: this.mfaCode,
        mobilePhoneNumber: this.mobilePhoneNumber,
        phoneNumberOptIn: this.phoneNumberOptIn,
        redirectUri: this.getLoginRedirectUri,
        state: this.getRedirectUrl('Native')
          .split('&')[1]
          .substring(stateQueryParameter),
        host: window.location.origin,
      };

      const response = await this.$http.post(appSettings.apiBaseUrl + 'login/native', credentials);
      if (response.data.statusEnum == 'Success') {
        await this.setAuthenticatedUserId(response.data.userId);
        this.hideMessage();
        if (response.data.redirectUri) {
          window.location.assign(response.data.redirectUri);
        } else {
          this.$_cb.router.changeView(appSettings.viewLogin);
        }
      } else if (response.data.statusEnum == 'Fail') {
        this.showMessage({ template: 'error', message: response.data.message });
      } else if (response.data.statusEnum == 'TempMFARequired') {
        this.showMessage({ template: 'info', message: "Please enter the code sent to your phone or email." });
        this.mfaRequired = true;
        this.mobilePhoneRequired = false;
      } else if (response.data.statusEnum == 'TempMFAMobilePhoneRequired') {
        this.showMessage({ template: 'info', message: "A mobile phone number is needed for Multi-Factor Authentication." });
        this.mobilePhoneRequired = true;
        this.mfaRequired = false;
      }
    },

    async handleForgot() {
      this.hideMessage();
      if (!this.username) {
        this.showMessage({ template: 'error', message: 'Please enter your Username.' });
      } else {
        var response = await this.$http.post(
          appSettings.apiBaseUrl + 'login/native/forgotpassword',
          { username: this.username.trim() }
        );
        this.showMessage({ template: 'info', message: response.data.message });
      }
    },

    handleAuth0Redirect() {
      window.location.replace(this.getRedirectUrl('SSO'));
    },
    handleSpInitiatedSSO(config) {
      window.location.replace(config.spInitiatedSsoUrl);
    },
  },
};
</script>

<style scoped lang="scss">
@import '@clickboarding/style/colors';
.cb-error-input {
  background-color: $cb-white;
  bottom: 24px;
  right: 0;
  z-index: 1000;
  width: 100%;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.35);
  border-radius: 1px;
  padding: 1em;
  border-top: 3px solid #b32737;
}
</style>
