<template>
  <user-menu v-if="header"
    :greeting="header.account.greetingLabel"
    :name="name"
    :supportTitle="header.support.title"
    :logoutLabel="header.logout.label"
    :logout="logout"
    :header="header">
      <template slot="support">
        <div v-if="header.support && header.support.phone" class="user-menu-contact-section">
          <svg class="user-menu-icon-contact">
            <use v-bind="{'xlink:href': iconPaths.phone }"></use>
          </svg>
          <a :href="'tel:' + header.support.phone" class="tel">{{header.support.phone}}</a>
        </div>

        <div v-if="header.support && header.support.email" class="user-menu-contact-section">
          <svg class="user-menu-icon-contact">
            <use v-bind="{'xlink:href': iconPaths.email }"></use>
          </svg>
          <a :href="'mailto:' + header.support.email" class="mail">{{header.support.email}}</a>
        </div>

        <div v-if="header.support && header.support.showDefaultMessage">
          {{header.support.defaultMessage}}
        </div>
        
        <div class="h-line"></div>

        <p v-if="header.support.hours">{{header.support.hours.label}}: {{header.support.hours.value}}</p>
        <p v-if="header.support.systemLabel">{{header.support.systemLabel}}:<br /> {{navigator.browser.name}} {{navigator.browser.version}} {{navigator.os.name}} {{navigator.os.version}}</p>
      </template>
  </user-menu>
</template>

<script>
import DeviceDetector from 'ua-parser-js';
import UserMenu from 'common/components/header/cb-user-menu.vue'
import SupportIcon from 'common/assets/icons/header/support-badge.svg'
import SupportActiveIcon from 'common/assets/icons/header/support-badge-active.svg'
import UserIcon from 'common/assets/icons/header/user-badge.svg'
import UserActiveIcon from 'common/assets/icons/header/user-badge-active.svg'
import EmailIcon from 'common/assets/icons/contact/email.svg'
import PhoneIcon from 'common/assets/icons/contact/phone.svg'

export default {
  name: 'cb-user-menu',
  components: {
    UserMenu
  },
  props: {
    header: {
      type: Object,
      required: false
    },
    logout: {
      type: Function,
      required: true
    }
  },
  created () {
    let deviceDetector = new DeviceDetector();
    this.navigator = deviceDetector.getResult();

    this.iconPaths = {
      support: '#' + SupportIcon.id,
      supportActive: '#' + SupportActiveIcon.id,
      account: '#' + UserIcon.id,
      accountActive: '#' + UserActiveIcon.id,
      email:'#' + EmailIcon.id,
      phone:'#' + PhoneIcon.id
    }
  },
  computed: {
    name () {
      return this.header.account.firstName + ' ' + this.header.account.lastName
    }
  }
}
</script>

<style scoped lang="scss">
  .user-menu-contact-section {
    display: flex;
    align-content: center;
    margin-bottom: .5rem;
    word-break: break-all;

    .tel, .mail {
      display: inline-block;
      line-height: 1.5rem;
    }
  }
  
  .user-menu-icon-contact {
    display: inline-block;
    overflow: visible;
    margin-right: 1rem;
    height: 1.5rem;
    width: 1.5rem;
  }
</style>
