<template>
    <div></div>
</template>

<script>
import { authService } from 'modules/common.module.js';
import appSettings from "appSettings";

export default {
    name: "native-logout-view",
    data() {
        return {
            returnToUrl: null
        }
    },
    mounted() {
        authService.clearSession();

        this.returnToUrl = this.$_cb.route.query.returnTo;
        if(this.returnToUrl) {
            window.location.href = this.returnToUrl;
        }
        else {
            this.$_cb.router.changeView(appSettings.viewLogout);
        }
    }
}
</script>
