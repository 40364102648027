import 'angular-route'; //provides the 'ngRoute' module
import appSettingsModule from 'common/app-settings.module';

export default angular.module('candidate-app-routes-module', [ appSettingsModule, 'ngRoute'])
  .config(['$routeProvider', 'appSettings',
    function ($routeProvider, appSettings) {
      $routeProvider.
      //login app
      when(appSettings.viewLogin, {
        template: '',
        controller: 'loginCtrl',
        caseInsensitiveMatch: true
      }).
      when(appSettings.viewLoginCallback, {
        template: '',
        controller: 'loginCallbackCtrl',
        caseInsensitiveMatch: true
      }).
      when(appSettings.viewAutoLogin, {
          template: '',
          controller: 'autoLoginCtrl',
          caseInsensitiveMatch: true
      }).
      when(appSettings.viewLogout, {
        template: require('login/logoutView.html'),
        controller: 'logoutCtrl',
        controllerAs: 'vm'
      }).
      when(appSettings.viewSignedIn, {
        template: '',
        controller: 'signedInCtrl',
        caseInsensitiveMatch: true
      }).
      //native login
      when(appSettings.viewNativeLoginInitial, {
        template: '',
        controller: 'nativeLoginInitialCtrl',
        caseInsensitiveMatch: true
      }).
      when(appSettings.viewNativeLoginDefault, {
        template: require('login/nativeLoginDefaultView.html'),
        controller: 'nativeLoginDefaultCtrl',
        controllerAs: 'vm'
      }).
      when(appSettings.viewInitial, {
        template: '',
        controller: 'firstTimeLoginCtrl',
        caseInsensitiveMatch: true
      }).
      when(appSettings.viewNativeLogout, {
        template: require('login/nativeLogoutView.html'),
        controller: 'nativeLogoutCtrl',
        caseInsensitiveMatch: true
      }).
      when(appSettings.customLoginConfig, {
        template: require('login/customLoginConfig.html'),
        controller: 'customLoginConfigCtrl',
        caseInsensitiveMatch: true
      }).
      when(appSettings.viewProfile, {
        template: require('login/profileView.html'),
        controller: 'profileCtrl',
        controllerAs: 'vm'
      }).
      when(appSettings.viewReset, {
        template: require('login/resetView.html'),
        controller: 'resetCtrl',
        controllerAs: 'vm'
      }).
      when(appSettings.viewSecurity, {
        template: require('login/securityView.html'),
        controller: 'securityCtrl',
        controllerAs: 'vm'
      }).
      when(appSettings.viewProcess, {
        template: require('process/processView.html'),
        controller: 'processCtrl',
        controllerAs: 'vm',
      }).
      when(appSettings.viewActivitiesOverview, {
        template: require('activities/overview.html'),
        controller: 'overviewCtrl',
        controllerAs: 'vm',
      }).
      when(appSettings.viewForms, {
        template: require('forms/formsView.html'),
        controller: 'formsCtrl',
        controllerAs: 'vm'
      }).
      when(appSettings.viewFormSplash, {
        template: require('forms/formSplash.html'),
        controller: 'formSplashCtrl',
        controllerAs: 'vm'
      }).   
      when(appSettings.viewError, {
        template: require('general/errorView.html'),
        controller: 'errorCtrl',
        controllerAs: 'vm'
      }).
      when(appSettings.viewUnsupported, {
        template: require('general/unsupportedView.html'),
        controller: 'unsupportedCtrl',
        controllerAs: 'vm'
      }).
      otherwise({
        redirectTo: function (routeParams, path, search) {
          const hashParamName = '?hash=';

          //this provides support for navigation to the site with
          //a query param on the path instead of the hash. This is needed
          //to support redirect after logout from auth0, as the hash fragment
          //is being dropped.
          if(window.location.search.substring(0, 6) === hashParamName) {
            const paramValue = window.location.search.substring(hashParamName.length);
            let route;
            try {
              route = decodeURIComponent(paramValue);
            }
            catch (error) {
              route = appSettings.viewLogin;
            }
            window.location.replace(window.location.origin + appSettings.baseUrl + '#' + route);
          }
          else {
            return appSettings.viewLogin;
          }
        }
      });
    }
  ])
  .name;
