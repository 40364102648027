import { IconProvider } from 'common/icon.module';
import FormNavigationService from 'common/forms/form-navigation.service';
import ESignatureTitleIcon from 'common/assets/icons/activity-title/e-signature-title-icon.svg';
import GenericFormTitleIcon from 'common/assets/icons/activity-title/generic-form-title-icon.svg';
import PrimaryMessageBus from 'common/components/cb-primary-message-bus';
import { FeatureFlags } from '../FeatureFlags';
import store from '../store';

export default function formsCtrl(
  $rootScope,
  $scope,
  $http,
  $window,
  $timeout,
  appSettings,
  locationSvc,
  FormService,
  CommonModalService
) {
  var vm = this;
  vm.model = {
    pageRendered: false,
    showDataChangedMsg: true,
    nextText: "Next",
    nextClicked: false
  };
  vm.renderVueComponent = false;

  vm.iconPath = {
    genericForm: IconProvider.getPath('Activity-Title-Generic-Form-Icon'),
    eSignatureForm: IconProvider.getPath('Activity-Title-E-Signature-Icon'),
    nextBtn: IconProvider.getPath('Generic-Right-Arrow-Icon'),
    backBtn: IconProvider.getPath('Generic-Left-Arrow-Icon'),
    homeIcon: IconProvider.getPath('Activity-Title-My-Activities-Icon')
  };

  $http.get(locationSvc.fetchQuery()).then(function(data) {
    setupViewData(data.data);
  });

  function setupViewData(data) {
    vm.content = data;
    vm.processUrl = data.processSummaryUrl;
    FormService.pageData(data, true).then(render);
  }

  function render(data) {
    vm.model.nextClicked = false;
    vm.currents = data;

    $window.scrollTo(0, 0);
    vm.model.showDataChangedMsg = true;
    vm.model.handleBackContinueClick = null;

    vm.viewTitleIcon = isCurrentPageTypeIn('ESignatureCreate') ? ESignatureTitleIcon : GenericFormTitleIcon;

    vm.backLabel = vm.currents.page.backLabel;
    vm.backToMyActivitiesLabel = vm.currents.page.backToMyActivitiesLabel;
    vm.nextText = vm.currents.page.nextLabel;
    vm.backDisabled =
      vm.currents.formIndex === 0 && vm.currents.pageIndex === 0;

    // Akin to changing the route, emit a message to clear the primary message bar
    // when moving between pages.
    PrimaryMessageBus.$emit('clear-all-messages');

    vm.vueComponentRendered = false;
    isVueRender();
  }

  vm.vueComponentCallback = function () {
    // Our vue components call this method when digests are already in progress,
    // so wrapping this in a $timeout ensures that this change will be evaluated
    // after that digest has completed and be evaluated and re-rendered
    $timeout(() => {
      vm.model.pageRendered = true;
    });
  }

  function isVueRender () {
    if (vm.currents.page.typeEnum === 'DocumentUpload'
      || vm.currents.page.typeEnum === 'SavagePage'
      || vm.currents.page.typeEnum === 'SignedDocument'
      || vm.currents.page.typeEnum === 'Review'
    ) {
      // this variable is used by ui-messages directive
      vm.vueComponentRendered = true;

      // This method is called when digests are already in progress,
      // so wrapping this in a setTimeout ensures that this change will be evaluated
      // after that digest has completed and we can them scope apply the changes
      setTimeout(function(){
        vm.renderVueComponent = true;
        $scope.$apply();
      });
    }
  }

  function confirm(action) {
      console.log("formsCtrl.js confirm");
      if (vm.currents.page.statusEnum === "Completed") {
      action();
    } else {
      CommonModalService.open(
        vm.currents.page.backConfirm,
        "confirm",
        function modalConfirm() {
          action();
          PrimaryMessageBus.$emit('clear-all-messages');
          CommonModalService.close();
        }
      );
    }
  }

  vm.goBack = function() {
    vm.goToPage(vm.currents.pageIndex - 1);
  };

  // this gets passed into directives and triggered from within them
  // called from the review page
  vm.goToPage = function(pageIndex) {
    vm.model.pageRendered = false;
    vm.renderVueComponent = false;
    FormService.goToPage(
      vm.currents,
      pageIndex,
      vm.content.processItemsList
    ).then(render);
  };

  vm.backClick = function(e) {
    confirm(vm.goBack);
  };

  vm.backToActivities = function() {
    confirm(vm.exitForms);
  };

  vm.showNavigationButtons = function() {
      return vm.model.pageRendered &&
        ((vm.currents.page.subTypeEnum !== 'Symmetry'
            && vm.currents.page.subTypeEnum !== 'FormI9Old'
            && vm.currents.page.subTypeEnum !== 'Retain'
            && !(vm.currents.page.subTypeEnum === 'FormI9' && store.getters['features/hasFeature'](FeatureFlags.MultiPageI9Enabled))) 
          || vm.currents.page.typeEnum === 'SignedDocument');
  }

  function currentPageDataExists() {
    return 'currents' in vm && 'page' in vm.currents;
  }

  //can be overloaded with multiple types (i.e. vm.isCurrentPageTypeIn('type1', 'type2', 'type3'))
  function isCurrentPageTypeIn (type) {
    var args = (arguments.length === 1 ? [arguments[0]] : Array.apply(null, arguments));
    return currentPageDataExists() && args.indexOf(vm.currents.page.typeEnum) >= 0;
  };

  // this gets passed into directives and triggered from within them
  //called from the review/page/signedDoc directives
  vm.model.nextSuccess = function (data) {
      console.log("formsCtrl.js nextSuccess");
      vm.model.pageRendered = false;
    // this variable is used for a vue component to (re)render
    vm.renderVueComponent = false;
    var oldCurrents = vm.currents;
    var changedToStepComplete = (!vm.content.isStepComplete) && (data.processItems.isStepComplete);
    var finishedReviewing = vm.content.isStepComplete && oldCurrents.isLastPageOfStep;

    var changedToDeclined = !vm.content.isStepDeclined && data.processItems.isStepDeclined;
    var lastStepWasDeclined = oldCurrents.form.isDeclined && data.processItems.isStepDeclined;

    var changedToStopped = vm.content.processHasStartedSteps && !data.processItems.processHasStartedSteps;
    var changedToProcessComplete = !vm.content.isProcessCandidateComplete && data.processItems.isProcessCandidateComplete;

    vm.content = data.processItems;

    // exits out to splash screen with context of which process
    if (changedToDeclined || lastStepWasDeclined || changedToStopped || changedToProcessComplete) {
        console.log("formsCtrl.js nextSuccess changedToDeclined || lastStepWasDeclined || changedToStopped || changedToProcessComplete");
        locationSvc.changeView(appSettings.viewFormSplash, vm.processUrl);
    }
    // exits out of form to activies list view (of current process flow)
    else if (changedToStepComplete || finishedReviewing) {
        console.log("formsCtrl.js nextSuccess changedToStepComplete || finishedReviewing");
        vm.exitForms();
    } else {
        // this will call the render function
        console.log("formsCtrl.js nextSuccess setupViewData");
      setupViewData(vm.content);
    }
  };

  // this gets passed into directives and triggered from within them
  // called from the review page
  vm.model.previewPdf = function(previewPdfUrl) {
    CommonModalService.open({'pdfUrl': previewPdfUrl}, 'pdf', function modalConfirm() {
      CommonModalService.close();
    });
  };

  function dispatchCustomEvent(eventName, data) {
    var evt = document.createEvent("CustomEvent");
    evt.data = data;
    evt.initCustomEvent(eventName, true, false, null);
    window.dispatchEvent(evt);
  }

  vm.exitForms = function() {
      console.log("formsCtrl.js exitForms changeForm");
      locationSvc.changeView(
      appSettings.viewProcess,
      vm.content.processSummaryUrl
    );
  };

  vm.nextClick = function (e) {
      console.log("formsCtrl.js nextClick handleNextClick cbFormCtrl:nextClick");
    vm.model.nextClicked = true;
    FormNavigationService.handleNextClick()
    $rootScope.$broadcast("cbFormCtrl:nextClick");
  };

  var changeFormUnsubscribe = $rootScope.$on("cbFormCtrl:changeForm",
    function(e, newFormIndex) {
    FormService.changeForm(e, newFormIndex, vm.content).then(      
      function(data) {
            console.log("formsCtrl.js changeFormUnsubscribe changeForm goToPage");
        FormService.goToPage(data, data.pageIndex);
      }
    );
  }
);

  var changePageUnsubscribe = $rootScope.$on('cbFormCtrl:changePage', function (e, newPageIndex) {
    if (newPageIndex !== vm.currents.pageIndex) {
      FormService.goToPage(vm.currents, newPageIndex, vm.content.processItemsList).then(render);
     }
  });

  $scope.$on("$destroy", function() {
    changeFormUnsubscribe();
    changePageUnsubscribe();
  });
}

formsCtrl.$inject = [
  "$rootScope",
  "$scope",
  "$http",
  "$window",
  "$timeout",
  "appSettings",
  "locationSvc",
  "FormService",
  "CommonModalService"
];
