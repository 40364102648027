export default function cbPasswordFields (PasswordService) {

	var ctrl = function ($scope, $element, $attrs) {
		var vm = this;

		vm.model = $scope.model;
		vm.password2Focus = false;
		vm.showPasswordMatchMessage = false;
		vm.passwordExactMatch = false;

		vm.tabindex1 = $attrs.tabIndexOne;
		vm.tabindex2 = $attrs.tabIndexTwo;
		vm.required = $attrs.required;
		vm.password1 = null;
		vm.password2 = null;

		// bind our validations to the outer form object for the view level controller to have access
		$scope.form.validations = PasswordService.validations;

		//// FUNCTIONS / VALIDATIONS
		function setPasswordsPartialMatch(matchResult, exactMatchResult) {
			vm.passwordsMatch = matchResult;
			vm.passwordExactMatch = exactMatchResult;
			$scope.form.password2.$setValidity("passwordsMatch", matchResult);
		}

		function setPasswordsExactMatch(matchResult, exactMatchResult) {
			vm.passwordsMatch = matchResult;
			vm.passwordExactMatch = exactMatchResult;
			$scope.form.password2.$setValidity("passwordsMatch", exactMatchResult);
		}

		vm.enterPassword2 = function() {
			vm.password2Focus = true;
			vm.showPasswordMatchMessage = true;
			//exact match checks when first focused
			PasswordService.compare(vm.model.password1, vm.model.password2, true, setPasswordsExactMatch);
		};

		vm.typingPassword2 = function() {
			vm.password2Focus = true;
			vm.showPasswordMatchMessage = true;
			//partial match checks when typing begins
			PasswordService.compare(vm.model.password1, vm.model.password2, false, setPasswordsPartialMatch);
		};

		vm.exitPassword2 = function() {
			vm.password2Focus = false;
			vm.showPasswordMatchMessage = false;
			//exact match checks when not focused
			PasswordService.compare(vm.model.password1, vm.model.password2, true, setPasswordsExactMatch);
		};

		//// SET UP FIELDS / VALUES
		$scope.$watch('password1', function(newval, oldval){
			if(vm.password1 === null || newval !== oldval){
				vm.password1 = newval;
			}
		});
		$scope.$watch('password2', function(newval, oldval){
			if(vm.password2 === null || newval !== oldval){
				vm.password2 = newval;
			}
		});

	};

	return {
		restrict: "E",
		replace: true,
		controller: ["$scope", "$element", "$attrs", ctrl],
		controllerAs: 'vm',
		template: require('components/password-fields/cbPasswordFields.tmpl.html'),
		scope: {
			password1: '=passwordOne',
			password2: '=passwordTwo',
			model: '=',
			form: '='
		}
	};
};

cbPasswordFields.$inject = ['PasswordService'];
