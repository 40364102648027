import UiMessages from 'store/modules/ui-messages';
import Auth from 'store/modules/auth';
import Features from 'store/modules/features';
import CustomLogin from 'store/modules/customLogin'
export default function () {
  return {
    'ui-messages': UiMessages,
    'auth': Auth,
    'features': Features,
    'customLogin': CustomLogin,
  };
};