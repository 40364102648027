import UiMessagesModule from 'modules/uiMessages.module';

import submitButton from 'general/submitButton';
import radioGroup from 'general/radioGroup';
import cbValidate from 'components/form-fields/cbValidate.directive';
import ClickFormProgressBar from 'components/form-progress-bar/click-form-progress-bar.vue';

export default angular.module('candidate-ui-components-module', [UiMessagesModule])
	.directive('submitButton', submitButton)
	.directive('radioGroup', radioGroup)
	.directive('cbValidate', cbValidate)
	.value('click-form-progress-bar', ClickFormProgressBar)
	.name;

