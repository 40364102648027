import { IconProvider } from 'common/icon.module';
import Ally from 'ally.js';
import CbPdfViewerDataProvider from "common/components/pdf/cb-pdf-viewer-data-provider";

export default function cbModalDirective ($rootScope, $location, $timeout, appSettings, CommonModalService, $ocLazyLoad) {
  var ctrl = function ($scope) {
    var vm = this;

    vm.icons = {
      leaving: IconProvider.getPath('Activity-Warning-Icon'),
        close: IconProvider.getPath('Ui-X-Icon')
    };

    vm.close = function(e) {
      CommonModalService.close();
    };

    var closeModalWithEsc = function (evt) {
      if (!vm.showModal) return;

      evt = evt || window.event;
      var isEscape = false;

      isEscape = (evt.keyCode == 27);

      if (isEscape) {
        vm.close();
        $scope.$apply();
      }

      trapTabKey(document.querySelector('.cb-modal'), evt);
    }

    function trapTabKey(node, event) {
      if (event.key !== 'Tab') return;

      var focusableChildren = getFocusableChildren(node);
      var focusedItemIndex = focusableChildren.indexOf(document.activeElement);

      // If the SHIFT key is being pressed while tabbing (moving backwards) and
      // the currently focused item is the first one, move the focus to the last
      // focusable item from the dialog element
      if (event.shiftKey && focusedItemIndex === 0) {
        focusableChildren[focusableChildren.length - 1].focus();
        event.preventDefault();
        // If the SHIFT key is not being pressed (moving forwards) and the currently
        // focused item is the last one, move the focus to the first focusable item
        // from the dialog element
      } else if (
        !event.shiftKey &&
        focusedItemIndex === focusableChildren.length - 1
      ) {
        focusableChildren[0].focus();
        event.preventDefault();
      }
    }

    function getFocusableChildren(node) {
      var elements = Ally.query.focusable({
        context: '.cb-modal',
        includeContext: true,
        strategy: 'quick',
      });

      return elements;
    }

    function captureKeyPressInModal (e) {
      closeModalWithEsc(e);
      trapTabKey(document.querySelector('.cb-modal'), e);
    }

    CommonModalService.onOpen(function(event, data) {
      if (data.data.pdfUrl) {
        import('common/components/pdf/cb-pdf-viewer-loader').then(pdfLoader => {
          pdfLoader.load().then(pdfViewerData => {
            $scope.pdfViewerLabels = pdfViewerData.labels;

            $ocLazyLoad.load(pdfViewerData.module).then(() => {
              $scope.pdfUrl = data.data.pdfUrl;
            })
          });
        });
      }

      vm.showModal = data.show;
      vm.template = data.template;
      vm.action = data.action;
      vm.data = data.data;

      // using $timeout to wait for scope to digest
      $timeout(function() {
        // Best effort, scroll the modal div back to the top
        const modal = document.querySelector('.cb-modal');
        if (modal) modal.scrollTop = 0;
      }, 0);

      // using $timeout to wait for scope to digest
      $timeout(function() {
        // Best effort, set focus on close icon when modal is opened
        const modal = document.querySelector('.cb-modal');
        if (modal) modal.focus();
      }, 200);

      document.addEventListener('keydown', captureKeyPressInModal);
    });

    CommonModalService.onClose(function(event, data) {
      vm.showModal = data.show;
      vm.template = null;
      $scope.pdfUrl = null;

      document.removeEventListener('keydown', captureKeyPressInModal);
    });
  };

  return {
    restrict: "E",
    replace: true,
    template: require('common/components/modal/modal.tmpl.html'),
    controller: ["$scope", ctrl],
    controllerAs: 'vm',
    scope: {
    },
    bindToController: true
  };
};

cbModalDirective.$inject = ['$rootScope', '$location', '$timeout', 'appSettings', 'CommonModalService', '$ocLazyLoad'];
