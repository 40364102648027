<template>
  <section class="apply-global-styles">
    <custom-login-form
      v-if="showCandidateForm || showIdPLoginButton || showAuth0SignInButton"
      :headerTitle="headerTitle"
      :showCandidateForm="showCandidateForm"
      :showIdPLoginButton="showIdPLoginButton"
      :showAuth0SignInButton="showAuth0SignInButton"
    ></custom-login-form>
    <div
      v-else
      style="border:solid 1px #3656A6; min-width:250px; max-width:350px; text-align:center; margin:5% auto; padding:3em 0em; position:relative;"
    >
      <img id="lock-icon" src="assets/Lock.svg" style="height:250px; margin-bottom:1em;" />
    </div>
  </section>
</template>

<script>
import { contentService } from 'modules/common.module.js';
import { mapGetters, mapMutations, mapActions } from 'vuex';
import UiMessages from '../components/ui-messages/ui-messages.vue';
import CustomLoginForm from '../login/custom-login-form.vue';
import { FeatureFlags } from '../FeatureFlags';
export default {
  name: 'custom-login-config',
  components: {
    UiMessages,
    CustomLoginForm,
  },
  data() {
    return {
      headerTitle: '',
      showCandidateForm: true,
      showIdPLoginButton: false,
      showAuth0SignInButton: false,
    };
  },
  computed: {
    ...mapGetters('features', ['hasFeature']),
    ...mapGetters('customLogin', ['getRedirectUrl', 'hasRedirectType']),
  },
  methods: {
    ...mapActions('customLogin', ['getClientConfiguration', 'nativeLoginInitial']),
    ...mapMutations('features', ['setFeatureFlags']),
  },
  async created() {
    this.headerTitle = contentService.getString('headerTitle.message');
    let query = this.$_cb.router.getDataUrl();
    await this.getClientConfiguration(query);
    await this.nativeLoginInitial(query);

     //SSO redirect type is Auth0 oidc
    if (this.hasRedirectType('SSO')) {
      if (this.hasFeature(FeatureFlags.CandidateAuth0AutoRedirectEnabled)) {
        window.location.replace(this.getRedirectUrl('SSO'));
      }

      this.showAuth0SignInButton = this.hasFeature(FeatureFlags.CandidateAuth0SignInButtonEnabled);
    }

    this.showIdPLoginButton = this.hasFeature(FeatureFlags.CandidateInitiatedSsoButtonEnabled);

    this.showCandidateForm = this.hasFeature(FeatureFlags.ShowCandidateSignIn);
  },
};
</script>

<style scoped lang="scss">
@import '@clickboarding/style/colors';
.cb-error-input {
  background-color: $cb-white;
  bottom: 24px;
  right: 0;
  z-index: 1000;
  width: 100%;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.35);
  border-radius: 1px;
  padding: 1em;
  border-top: 3px solid #b32737;
}
</style>
