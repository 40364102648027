<template>
  <section>
    <div class="radio-group padding-med-B">
        <input id="rDisagree"
             type="radio"
             name="agreementRadioButton"
             :value="disagree.value"
             @change="action(disagree.value)"
             v-model="agreeDisagree"
             required/>
      <label for="rDisagree">{{ disagree.label }}</label>
      <input id="rAgree"
             type="radio"
             name="agreementRadioButton"
             @change="action(agree.value)"
             :value="agree.value"
             v-model="agreeDisagree"
             required/>
      <label for="rAgree">{{ agree.label }}</label>
    </div>
    <ui-messages v-if="agreeDisagree === disagree.value"></ui-messages>
  </section>

</template>

<script>
import UiMessages from "../components/ui-messages/ui-messages";
import {mapActions} from 'vuex'

export default {
  name: "cb-radio-group",
  components: {
    UiMessages
  },
  data () {
    return {
      agreeDisagree: null
    }
  },
  props: {
    value: {
      required: true
    },
    agree: {
      type: Object
    },
    disagree: {
      type: Object
    },
    messageType: {
      type: String
    },
    message: {
      type: String
    }
  },
  methods: {
    ...mapActions('ui-messages', ['showMessage', 'hideMessage']),

    action(value) {
      this.$emit('input', value)
      if (value === this.disagree.value) {
        var payload = {
          template: this.messageType,
          message: this.message
        }
        this.showMessage(payload);
      } else {
        this.hideMessage();
      }
    }
  }
}
</script>