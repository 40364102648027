export default function CommonEventService ($rootScope, appSettings) {
	if (!('appEventPrefix' in appSettings)) {
		throw 'The CommonEventService requires an appSetting for appEventPrefix. Suggested setting is cb:[appName]:';
	}

	var eventTypes = {};
	var service = {};

	function getUnsubscribeKey(unsubscribe) {
		return btoa(unsubscribe.toString());
	}

	function registerUnsubscribe(event, unsubscribe) {
		var key = getUnsubscribeKey(unsubscribe);
		eventTypes[event].unsubscribes[key] = unsubscribe;
		eventTypes[event].subscribers = Object.keys(eventTypes[event].unsubscribes).length;
	}

	function executeUnsubscribe(event, unsubscribe) {
		var key = getUnsubscribeKey(unsubscribe);
		if (key in eventTypes[event].unsubscribes) {
			eventTypes[event].unsubscribes[key]();
			delete eventTypes[event].unsubscribes[key];
			eventTypes[event].subscribers = Object.keys(eventTypes[event].unsubscribes).length;
		}
	}

	function validateEventExists(event) {
		if (!(event in eventTypes)) {
			throw 'An event named ' + event + ' has not been registered.';
		}
	}

	function validateEventNotRegistered(event) {
		if (event in eventTypes) {
			throw 'An event named ' + event + ' has already been registered.';
		}
	}

	function getNamespacedEvent(event) {
		return appSettings.appEventPrefix + event;
	}

	service.register = function (event) {
		var event = getNamespacedEvent(event);
		validateEventNotRegistered(event);
		eventTypes[event] = {
			subscribers: 0,
			published: 0,
			unsubscribes: {}
		};
	};

	service.subscribe = function (event, callback, scope) {
		var event = getNamespacedEvent(event);
		validateEventExists(event);

		var baseUnsubscribe = $rootScope.$on(event, callback);

		registerUnsubscribe(event, baseUnsubscribe);

		var unsubscribe = function () {
			executeUnsubscribe(event, baseUnsubscribe);
		};

		if (scope) {
			scope.$on('$destroy', unsubscribe);
		}

		return unsubscribe;
	};

	service.publish = function (event, data) {
		var event = getNamespacedEvent(event);
		validateEventExists(event);

		$rootScope.$emit(event, data || {})
		eventTypes[event].published++;
	};

	return service;
};

CommonEventService.$inject = ['$rootScope', 'appSettings'];
