export default function cbSecurityQuestionFields (ContentService) {

  var ctrl = function ($scope, $element, $attrs) {
    var vm = this;

    vm.model = $scope.model;
    vm.questionGroup1 = null;
    vm.questionGroup2 = null;

    vm.question1Validation = {
      type: [{name:'required', message: ContentService.getString('profile.validationMessages.required')}],
      name: ContentService.getString('profile.questionGroups.questionLabel', {index: 1})
    };
    vm.answer1Validation = {
      type: [{name:'required', message: ContentService.getString('profile.validationMessages.required')}],
      name: ContentService.getString('profile.questionGroups.answerLabel', {index: 1})
    };
    vm.question2Validation = {
      type: [{name:'required', message: ContentService.getString('profile.validationMessages.required')}],
      name: ContentService.getString('profile.questionGroups.questionLabel', {index: 2})
    };
    vm.answer2Validation = {
      type: [{name:'required', message: ContentService.getString('profile.validationMessages.required')}],
      name: ContentService.getString('profile.questionGroups.answerLabel', {index: 2})
    };

    vm.question1Validation.error = (vm.form && vm.form['question1']) ? vm.form['question1'].$error : null;
    vm.answer1Validation.error = (vm.form && vm.form['answer1']) ? vm.form['answer1'].$error : null;
    vm.question2Validation.error = (vm.form && vm.form['question2']) ? vm.form['question2'].$error : null;
    vm.answer2Validation.error = (vm.form && vm.form['answer2']) ? vm.form['answer2'].$error : null;

    const translateForm = () => {
      vm.emptyOptionLabel = ContentService.getString('common.select.emptyOption');
      vm.question1Label = ContentService.getString('profile.questionGroups.questionLabel', {index: 1});
      vm.answer1Label = ContentService.getString('profile.questionGroups.answerLabel', {index: 1});
      vm.question2Label = ContentService.getString('profile.questionGroups.questionLabel', {index: 2});
      vm.answer2Label = ContentService.getString('profile.questionGroups.answerLabel', {index: 2});
    };

    translateForm();
    // UPDATE TRANSLATIONS AFTER LANGUAGE CHANGE
    $scope.$on('$langugeChanged', translateForm);

    // SET UP FIELDS / VALUES
    $scope.$watch('questionGroup1', function(newval, oldval){
      if(vm.questionGroup1 === null || newval !== oldval){
        vm.questionGroup1 = newval;
      }
    });
    $scope.$watch('questionGroup2', function(newval, oldval){
      if(vm.questionGroup2 === null || newval !== oldval){
        vm.questionGroup2 = newval;
      }
    });
  };

  return {
    restrict: "E",
    replace: true,
    controller: ["$scope", "$element", "$attrs", ctrl],
    controllerAs: 'vm',
    template: require('components/security-question-fields/cbSecurityQuestionFields.tmpl.html'),
    scope: {
      questionGroup1: '=questionGroup1',
      questionGroup2: '=questionGroup2',
      model: '=',
      form: '='
    }
  };
};

cbSecurityQuestionFields.$inject = ['ContentService'];