import { i18n } from 'common/lang-config';

export default function CommonErrorService () {
	var error;
	var svc = {};
	
	svc.defaultErrorData = {
		'btnLabel':'Done',
		'title': 'Oops',
		'message':'An unexpected error has occurred. If you continue to have problems, please contact Technical Support.',
		'support':{
			'title': "Technical Support",
			'phone':'1 (855) 283 - 5583',
			'email': i18n.t('common.error.email'),
			'hours':{
				'label':'Hours',
				'value': '7am-7pm CT Monday-Friday'
			},
			'systemLabel':'Your System'
		}
	};

	svc.hasError = false;
	svc.errorData = {};
	
	svc.saveError = function(error) {
		svc.errorData = error;
		svc.hasError = true;
	};
  
	svc.getErrorData = function() {
		if(!svc.hasError) {
			return null;
		}

		var hasErrorData = svc.hasError && svc.errorData && svc.errorData.code;
		return hasErrorData ? svc.errorData : svc.defaultErrorData;
	};

	return svc;
};

CommonErrorService.$inject = ['$q'];
