import EventBus from 'common/event-bus';

export const HttpEvents = Object.freeze({
    AuthError: 'http-auth-error'
});

// NOTE: For consistency with other event bus exports (which are usually
// named exports with a service), we export this as a named export rather
// than a default).
export const httpEventBus = new EventBus(HttpEvents);
