import appSettings from '../../appSettings';

const getDefaultState = () => {
  return {
    idPConfigurations: [],
    redirectUrls: {},
    loginRedirectUri:
      window.location.origin + appSettings.baseUrl + '#' + appSettings.viewLoginCallback,
  };
};

// state
const state = getDefaultState();

// getters
export const getters = {
  getRedirectUrl: (state) => (redirectType) => {
    return state.redirectUrls[redirectType];
  },
  hasRedirectType: (state) => (redirectType) => {
    return state.redirectUrls.hasOwnProperty(redirectType);
  },
  getLoginRedirectUri: (state) => {
    return state.loginRedirectUri;
  },
  getIdPConfigurations: (state) => {
    return state.idPConfigurations;
  }
};

// actions
export const actions = {
  reset({ commit }) {
    commit('resetState');
  },
  async getClientConfiguration({ commit }, query) {
    let loginConfigUrl = appSettings.apiBaseUrl + 'Login/CustomConfiguration';
    const response = await this._vm.$http.get(loginConfigUrl + query);
    commit('features/setFeatureFlags', response.data.clientFeatureFlags, { root: true });

    if(response.data.idPConfigurations.length > 0) {
      commit('setIdPConfigurations', response.data.idPConfigurations);
    }

    //first time users' "native" url is the profile page where they can fill out password/security questions
    //we don't want our auth0 sso users to go here--we want first time sso users to be redirected to their IdP so they can
    //sign in and kick off the sync process between our system and auth0.
    if (response.data.redirectUrls['SSO'] && response.data.redirectUrls['Native'].includes('profile')) {
      return window.location.replace(response.data.redirectUrls['SSO']);
    }
    //we have to check for the login/native/initial path because this path pertains to login
    //the backend constructs other kinds of redirect urls, some beginning with 'profile' which
    //pertain to password reset flows. these redirects need to be let through so
    //the user is redirected to the appropriate page
    if (!response.data.redirectUrls['Native'].includes('login/native/initial')) {
      return window.location.replace(response.data.redirectUrls['Native']);
    }
    commit('setRedirectUrls', response.data.redirectUrls);
  },
  async nativeLoginInitial({ getters }, query) {
    let url = appSettings.apiBaseUrl + 'login/native/initial';
    var params = new URLSearchParams(getters.getRedirectUrl('Native').split('?')[1]);
    const response = await this._vm.$http.post(url, {
      redirectUri: params.get('redirect_uri'),
      state: params.get('state'),
      host: window.location.origin,
    });

    if (response.data.statusEnum === 'Success') {
      if (response.data.redirectUri) {
        window.location.replace(response.data.redirectUri);
      } else {
        this._vm.$_cb.router.changeView(appSettings.viewLogin);
      }
    }
  },
};

// mutations
export const mutations = {
  setIdPConfigurations(state, configs) {
    state.idPConfigurations = configs;
  },
  setRedirectUrls(state, redirectUrls) {
    state.redirectUrls = redirectUrls;
  },
  resetState(state) {
    Object.assign(state, getDefaultState());
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
