import ErrorInterceptorModule from 'common/interceptors/error.interceptor';
import CommonErrorService from 'common/services/error.service';
import errorCtrl from 'general/errorCtrl';
import ErrorView from '../general/error-view';

export let commonErrorService;
export default angular.module('candidate-error-module', [ErrorInterceptorModule])
    .service('CommonErrorService', CommonErrorService)
    .controller('errorCtrl', errorCtrl)
    .value('error-view', ErrorView)
    .run(['$injector', function ($injector) {
        commonErrorService = $injector.get('CommonErrorService');
    }])
    .name;
