<template>  
  <section class="text-c bg-white" v-cloak>
    <h1 class="headline" v-if="data">{{data.header.label}}</h1>
    <div>
      <svg class="cb-icon cb-icon-due-date">
        <use v-bind="{'xlink:href': activityIconPath }"></use>
      </svg>
      <p>{{activityDue}}</p>
    </div>
    <div class="h-line slim"></div>
    <div class="padding-med" v-if="data">{{data.header.value}}</div>
    <div class="h-line slim"></div>
    <div class="cp-tiles" v-if="content">
        <div class="row no-gutters padding-med" v-bind:class="{'justify-content-center': content.length < 4}">
          <div v-for="(item, index) in content" :key="index" class="col-12 col-md-6 col-lg-4 col-xl-3 tile"
          v-bind:class="{'active': item.statusEnum == 'NotStarted' || item.statusEnum == 'InProgress', 'overdue': item.isOverdue, 'complete': item.statusEnum == 'Completed', 'declined': item.statusEnum == 'Declined'}"

          >
            <div class="content bg-white">
              <span class="cb-triangle-down marker"></span>
              <span class="counter">{{item.displayOrder}}</span>
              <h2 class="headline2">{{item.name}}</h2>
              <svg class="cb-icon cb-icon-activity">
                <use v-bind="{'xlink:href': cardIconPath(item.statusEnum)}"></use>
              </svg>
              <div class="padding-sm-T description">{{item.description}}</div>
              <button class="cb-btn primary"
                v-if="item.events.view"
                :disabled="item.statusEnum == 'NotReady'"
                v-bind:class="{'disabled': item.statusEnum == 'NotReady'}"
                v-on:click="handleStart(item.events.view.url)">
                  {{item.events.view.label}}
              </button>
            </div>
          </div>
        </div>
    </div>
    <div class="row no-gutters process-view-footer" v-if="showOverviewButton">
      <div class="h-line"></div>
      <div class="col-12" v-if="hasIncompleteOtherProcesses">
        <svg class="cb-icon cb-icon-process-view-title">
          <use class="default hover" v-bind="{'xlink:href': headerIconPath}"></use>
        </svg>
        <p>{{strings.futureActivities}}</p>
      </div>
      <div class="process-view-overview-button-container">
        <button class="cb-btn secondary" v-on:click="viewOverview()">{{strings.reviewCompleted}}</button>
      </div>
    </div>
</section>

</template>

<script>
import AppSettings from "appSettings";
import dueDateIcon from 'common/assets/icons/due-date/due.svg';
import overdueDateIcon from 'common/assets/icons/due-date/overdue.svg';
import { candidateProcessService , experienceService, processService, contentService } from 'modules/common.module.js';
import activityPending from 'common/assets/icons/activities/activity-pending.svg';
import activityGetStarted from 'common/assets/icons/activities/activity-get-started.svg';
import activityCompleted from 'common/assets/icons/activities/activity-completed.svg';
import activityWarning from 'common/assets/icons/activities/activity-warning.svg';
import myActivitiesTitleIcon from 'common/assets/icons/activity-title/my-activities-title-icon.svg';
 
export default {
  name: "process-view",
  data() {
    return {
      data: null,
      strings: null,
      content: null,
      otherProcesses: null
    }    
  },
  methods: {
    async fetchData(){
      var processTags = experienceService.getCurrentExperienceProcessTags();
      this.strings = contentService.getString('activities');
      
      const responses = await Promise.all([processService.get(), candidateProcessService.getAll(processTags)]) 
      const processResponse = responses[0]; 
      const processList = responses[1];
      this.data = processResponse.data;
      this.otherProcesses = processList.filter(process => process.id !== this.data.id);
      //if the requested process isn't actually part of the current experience,
      //redirect to the overview.
      if (!processTags.some(processTag => this.data.tags.includes(processTag))) {
        this.viewOverview();
      }
      this.content = this.data.processSummaryList; 
    },
    cardIconPath (status) {
      let iconId = null;
      switch (status) {
        case 'NotReady':
          iconId = activityPending.id;
        case 'NotStarted':
        case 'InProgress':
          iconId = activityGetStarted.id;
          break;
        case 'Completed':
          iconId = activityCompleted.id;
          break;
        case 'Declined':
        case 'Warning':
          iconId = activityWarning.id;
          break;
      }
      return '#' + iconId;
    },
    handleStart (url) {
      this.$_cb.router.changeView(AppSettings.viewForms, url);
    },
    viewOverview () {
      this.$_cb.router.changeView(AppSettings.viewActivitiesOverview);
    }
  },
  computed: {
    activityIconPath () {
      let iconId = null;
      if(this.data) 
      switch (this.data.statusEnum) {
        case 'Pending':
        case 'Completed':
          iconId = dueDateIcon.id;
          break;
        case 'Overdue':
          iconId = overdueDateIcon.id;
          break;
      }
      return '#' + iconId;
    },    
    activityDue () {
      if(this.data)
        return  this.data.status.label + ": " + this.data.status.value;
    },
    headerIconPath () {
      return "#"+ myActivitiesTitleIcon.id;
    },
    footerIconPath () {
      return '#' + myActivitiesTitleIcon.id;
    },
    showOverviewButton () {
      if(this.data && this.otherProcesses)
         return this.data.statusEnum === 'Completed' || this.otherProcesses.length > 0;   
    },
    hasIncompleteOtherProcesses () {
      if(this.otherProcesses){
        return this.otherProcesses.filter(process => process.statusEnum !== 'Completed').length > 0;    
      }
    }
  },  
  async beforeMount () {
    await this.fetchData();
  }
  
}
</script>
<style scoped lang="scss">
@import "@clickboarding/style/colors";

.process-view {
  background-color: $cb-white;
  padding: 1rem 2rem 2rem;
}

.status-icon {
  text-align: center;
  padding: 1.5rem;
}
</style>