<template>
  <section class="apply-global-styles">
    <footer class="cb-footer text-c padding-med">
      <div>&copy; {{ year }} Click Boarding, LLC. All Rights Reserved.</div>
      <div class="padding-sm-T" v-if="terms && privacy">
        <a href="" @click.prevent="openLegal(terms.url)">{{ terms.label }}</a> |
        <a href="" @click.prevent="openLegal(privacy.url)">{{ privacy.label }}</a>
      </div>
    </footer>
  </section>
</template>

<script>
import {commonModalService} from 'modules/common.module'
import {headerService} from "header/header.module";

export default {
  name: "click-footer",
  data() {
    return {
      year: new Date().getFullYear(),
      headerService
    }
  },
  computed: {
    terms () {
      return this.headerService.hasHeaderData() && this.headerService.headerData.data.terms
    },
    privacy () {
      return this.headerService.hasHeaderData() && this.headerService.headerData.data.privacy
    }
  },
  methods: {
    async openLegal(url) {
      const {data} = await this.$http.get(url)
      commonModalService.open({
        content: data.content,
        doneButton: data.doneButton
      }, 'legal');
    }
  }
}
</script>