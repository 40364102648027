var uiMessagesService = function ($rootScope) {
	var svc = {};

	$rootScope.$on('$routeChangeSuccess', function() {
    svc.hideMessage();
  });

	svc.obj = {
		template: null,
		message: null,
		visible: null
	},
	svc.showMessage = function (tmpl, msg) {
		svc.obj.template = tmpl;
		svc.obj.message = msg;
		svc.obj.visible = true;
	},
	svc.hideMessage = function () {
		svc.obj.template = null;
		svc.obj.message = null;
		svc.obj.visible = false;
	}

	return svc;
};

uiMessagesService.$inject = ['$rootScope'];

if (typeof app !== 'undefined') {
	app.factory('uiMessages', uiMessagesService);
}
else {
	module.exports = uiMessagesService;
}
